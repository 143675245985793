import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button } from "reactstrap";
import { externalParticulars, principleList, reviewParticulars } from "../../Settings/settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";


const ExternalEvaluation = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [externalData, setExternalData] = useState(externalParticulars);

    const getExternalData = async() => {
        await fetchWrapper(`${API_URL}/emissionsReport/disclosureQuestionsData/${financialYear}`)
        .then((res) => {
            const tempArray = externalParticulars?.map(ele => {
                const temp = res?.json?.find(item => item?.questionText === ele?.questionText);
                return temp ? temp : ele;
            })
            tempArray?.length > 0 ? setExternalData(tempArray) : setExternalData(externalParticulars)
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(financialYear) {
            getExternalData()
        }
    }, [financialYear])


    const externalColumn = [
        {
            headerName: '',
            field: 'questionText',
            editable: false,
            filter: false,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            flex: 5
        },
        {
            headerName: '1',
            field: 'p1Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '2',
            field: 'p2Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '3',
            field: 'p3Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '4',
            field: 'p4Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '5',
            field: 'p5Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '6',
            field: 'p6Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '7',
            field: 'p7Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '8',
            field: 'p8Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '9',
            field: 'p9Set1',
            editable: true,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ]

    const onSubmit = async() => {
        const data = {
            financialYear: financialYear,
            disclosureQuestionsData: externalData?.map((item, index) => ({ ...item, serialNo: 14}))
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
        .then((res) => {

        }, (err) => {
            console.log(err)
            if(err.status === 200) {
                setActive(6)
            } else if(err.status === 500) {
                toast.error('Something went wrong!')
            }
        })
    }
    
    return (
        <Fragment>
            <h4>External Evaluation</h4>
            <div className="mtop-1 company-profile">
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={externalData}
                        columnData={externalColumn}
                    />
                </div>
            </div>
            <div className="mtop-1">
                <div className="">
                    <h4>Note:</h4>
                    {
                        principleList?.map((item, index) => (
                            <p className="mb-0 ml-1">{index + 1} - <span className="ml-1">{item}</span></p>
                        ))
                    }
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(4)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default ExternalEvaluation;