import React, { Fragment, useEffect, useState, useRef } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { Button, Label, Modal, ModalBody, ModalHeader, Row, Col, FormGroup, InputGroup, InputGroupText } from "reactstrap";
import { Plus, Trash2, XCircle, X } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { thousandSeparator } from "../../api/helper";
import { GrEdit } from "react-icons/gr";
import { uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { PiFileArrowDownDuotone } from "react-icons/pi";


let error = false;
const MobileSources = ({ setSteps, preData, timeStamp, setMobileSourcesData, mobileData, requestId, setRequestId }) => {
    const [data, setData] = useState([]);
    const [noOfRow, setNoOfRow] = useState(1);
    const [vehicleTypeData, setVehicleTypeData] = useState([])
    const [onRoadVehicles, setOnRoadVehicles] = useState([]);
    const [nonRoadVehicles, setNonRoadVehicles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [biodieselPercent, setBiodieselPercent] = useState();
    const [ethanolPercent, setEthanolPercent] = useState();
    const msRef = useRef();
    const [rowIndex, setRowIndex] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState();
    const [isBiodiesel, setBiodiesel] = useState(false);
    const [isEthanol, setEthanol] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }


    const getVehicleYearData = async (vType, fType) => {
        let vehicleYear = [];
        setDeleteLoading(true);
        let bodyObject = {
            countryCode: preData?.countryCode,
            vehicleType: vType,
            fuelType: fType
        }
        const options = {
            method: 'POST',
            body: { ...bodyObject }
        }
        await fetchWrapper(`${API_URL}/emissionunits/vehicleYears`, options)
            .then((res) => {
                const data = res.json;
                vehicleYear = [...data];
            }, (err) => {
                console.log(err)
                setDeleteLoading(false)
            })
        return [...vehicleYear];
    }

    const getVehicleYears = async (data) => {
        let obj = {};
        let tempData = [...data];
        data?.filter(item => (item.fuelType))?.map(async (item, index) => {
            await getVehicleYearData(item?.vehicleType, item?.fuelType)
                .then((res) => {
                    obj.yearsArray = [...res];
                    tempData[index].yearsArray = [...res];
                    if(!res) {
                        tempData[index].vehicleYear = null;
                    }
                })
        })
        setDeleteLoading(false)
        return tempData;
        
    }

    const populateTable = async(currentData) => {
        const bioPercent = currentData?.find(ele => ele?.biodieselPercentage > 0);
        const ePercent = currentData?.find(ele => ele?.ethanolPercentage > 0);
        setBiodieselPercent(bioPercent?.biodieselPercentage);
        setEthanolPercent(ePercent?.ethanolPercentage);
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)]
            .map((_, index) => ({
                id: index + 1, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            }))];
        const getData = await getVehicleYears(tempData);
        setData(getData)
    }

    const loadData = async (savedData, cTimestamp) => {
        console.log(savedData, mobileData)
        if (mobileData?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(mobileData)
        } else if (mobileData?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if(mobileData?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, mobileData, populateTable)
            } else { //saved is latest
                notifyFunction(mobileData, populateTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        const bioPercent = cacheData?.mobilesourcesData?.find(ele => ele?.biodieselPercentage > 0);
        const ePercent = cacheData?.mobilesourcesData?.find(ele => ele?.ethanolPercentage > 0);
        setBiodieselPercent(bioPercent?.biodieselPercentage);
        setEthanolPercent(ePercent?.ethanolPercentage);
        loadData(cacheData?.mobilesourcesData, cacheData?.modifiedTs)
    }

    const getVehicleData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/vehicles/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json;
                setVehicleTypeData(data)
                setOnRoadVehicles(data?.filter((res) => res?.type === 'On Road')?.map(ele => ele?.vehicleTypeDescription))
                setNonRoadVehicles(data?.filter((res) => res?.type === 'Non Road')?.map(ele => ele?.vehicleTypeDescription))
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getVehicleData()
        getCache()
        const obj = [...Array(10)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }));
        setData(obj);
        // if (mobileData?.length > 0) {
        //     loadData()
        // }
    }, [])

    const handleClear = async(params) => {
        const updatedRowData = data.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false } :
                ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        const tempData = {
            ...cacheData,
            mobilesourcesData: updatedRowData?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        if (updatedRowData?.some(ele => ele?.vehicleTypeDescription?.includes('Biodiesel'))) {
            setBiodiesel(true)
        } else if (!updatedRowData?.some(ele => ele?.vehicleTypeDescription?.includes('Biodiesel'))) {
            setBiodieselPercent()
            setBiodiesel(false)
        }
        if (updatedRowData?.some(ele => ele?.vehicleTypeDescription?.includes('Ethanol'))) {
            setEthanol(true)
        } else if (!updatedRowData?.some(ele => ele?.vehicleTypeDescription?.includes('Ethanol'))) {
            setEthanolPercent()
            setEthanol(false)
        }
        setData(updatedRowData);
    };

    const handleUpload = async(e, params, nodeIndex) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            data[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('file', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=MobileSources&uploadPeriod=${preData?.uploadPeriod}
            &categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}
            &requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                let tempData = [...data];
                tempData[nodeIndex].documentId = res?.json?.id;
                tempData[nodeIndex].uploadedFileName = res?.json?.uploadedFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                setData(tempData)
                createCache(data?.filter(ele => ele?.sourceId))
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const downloadEvidence = async (paramsData) => {
        await fetchWrapper(`${API_URL}/document/downloadEvidence/${paramsData?.documentId}`)
            .then((err) => {
                console.log(err.json)
                if (err.status === 200) {
                    const link = document.createElement('a');
                    link.href = err?.json?.presignedUrl;
                    link.download = paramsData.uploadedFileName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
            .catch((err) => {
                console.log(err, 'asdasd')
            })
    }

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('roadType')
                    || params.data.hasOwnProperty('fuelUsage')
                    || params.data.hasOwnProperty('units')
                    || params.data.hasOwnProperty('distanceTraveled')) {
                    params.data.sourceId = `MS-${params.node.rowIndex + 1}`;
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 0.75,
            cellClass: 'disable-cell br-black'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: 'On Road or Non Road?',
            field: 'roadType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['On Road', 'Non Road'],
            },
            filter: false,
            editable: true,
            flex: 1.25,
            sortable: false,
        },
        {
            headerName: 'Vehicle Type',
            field: 'vehicleTypeDescription',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data.roadType === 'On Road') {
                    return { values: onRoadVehicles }
                }
                if (params.data.roadType === 'Non Road') {
                    return { values: nonRoadVehicles }
                }
            },
            valueGetter: (params) => {
                if(params.data.vehicleTypeDescription) {
                    let getVehicleData = vehicleTypeData?.find((item) => item?.vehicleTypeDescription === params.data?.vehicleTypeDescription);
                    params.data.resourceType = getVehicleData?.resourceType;
                    return params.data?.vehicleTypeDescription;
                }
                
            },
            filter: false,
            editable: true,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            flex: 2.5,
        },
        {
            headerName: 'Vehicle Year',
            field: 'vehicleYear',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.vehicleTypeDescription) {
                    if (params.data?.yearsArray?.length > 1 && !params.data?.yearsArray?.includes(null)) {
                        return { values: params.data?.yearsArray }
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.vehicleTypeDescription) {
                    if (params.data?.yearsArray?.includes(null)) {
                        params.data.vehicleYear = null;
                        return 'N/A';
                    }
                }
                if (params.data?.vehicleTypeDescription && !params.data.vehicleYear && params.data?.yearsArray?.includes(null)) {
                    params.data.vehicleYear = null;
                    return 'N/A'
                } else if(params.data?.vehicleTypeDescription && !params.data.vehicleYear && !params.data?.yearsArray?.includes(null)) {
                    params.data.vehicleYear = null;
                    return '';
                }
                if (params.data?.vehicleYear) {
                    return params.data?.vehicleYear !== 'N/A' ? Number(params.data?.vehicleYear) : 'N/A';
                }
            },
            filter: false,
            editable: (params) => {
                if (params.data?.vehicleTypeDescription) {
                    if (params.data?.yearsArray?.length > 1 && !params.data?.yearsArray?.includes(null)) {
                        return true
                    } return false
                }
            },
            autoHeight: true,
            wrapText: true,
            sortable: false,
            flex: 1,
        },
        {
            headerName: 'Fuel Usage',
            field: 'fuelUsage',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.fuelUsage ? thousandSeparator(params?.data?.fuelUsage?.toFixed(4)) : ''}</div>;
            },
            editable: true,
            flex: 1,
            sortable: true
        },
        {
            headerName: 'Unit',
            field: 'units',
            filter: false,
            editable: (params) => {
                let getVehicleData;
                if (params.data?.vehicleTypeDescription) {
                    getVehicleData = vehicleTypeData?.find((item) => item?.vehicleTypeDescription === params.data?.vehicleTypeDescription);
                    let unitData = getVehicleData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            autoHeight: true,
            wrapText: true,
            cellEditorParams: (params) => {
                let getVehicleData;
                if (params.data?.vehicleTypeDescription) {
                    getVehicleData = vehicleTypeData?.find((item) => item?.vehicleTypeDescription === params.data?.vehicleTypeDescription);
                    let unitData = getVehicleData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.vehicleTypeDescription) {
                    let getVehicleData = {};
                    getVehicleData = vehicleTypeData?.find((item) => item?.vehicleTypeDescription === params.data?.vehicleTypeDescription);
                    let unitData = getVehicleData?.units?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 1.25,
            sortable: false,
        },
        {
            headerName: 'Distance Travelled',
            field: 'distanceTraveled',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.distanceTraveled ?
                    thousandSeparator(params?.data?.distanceTraveled?.toFixed(4)) : ''}</div>;
            },
            flex: 1.25,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Distance Unit',
            field: 'distanceUnits',
            filter: false,
            editable: (params) => {
                if (params.data?.vehicleTypeDescription) {
                    let getVehicleData;
                    getVehicleData = vehicleTypeData?.find((item) => item?.vehicleTypeDescription === params.data?.vehicleTypeDescription);
                    let unitData = getVehicleData?.distanceUnits?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.vehicleTypeDescription) {
                    let getVehicleData;
                    getVehicleData = vehicleTypeData?.find((item) => item?.vehicleTypeDescription === params.data?.vehicleTypeDescription);
                    let unitData = getVehicleData?.distanceUnits?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.vehicleTypeDescription) {
                    let getVehicleData;
                    getVehicleData = vehicleTypeData?.find((item) => item?.vehicleTypeDescription === params.data?.vehicleTypeDescription);
                    let unitData = getVehicleData?.distanceUnits?.split(',');
                    if (unitData?.length === 1) {
                        params.data.distanceUnits = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.distanceUnits) {
                    return params.data?.distanceUnits
                }
            },
            flex: 1.25,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            tooltipValueGetter: (params) => {
                if(params.data.uploadedFileName) {
                    return params.data.uploadedFileName;
                }
            },
            cellRenderer: (params) => {
                if (!params.data.uploadedFileName) {
                    return (
                        <div>
                            <label className="upload-file-icon">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex)}
                                />
                                <AiOutlineCloudUpload />
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div onClick={() => downloadEvidence(params.data)}>
                            <PiFileArrowDownDuotone className="file-download" />
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 60,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        }
    ]

    const addingRows = (val) => {
        setNoOfRow(val)
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({
                id: data?.length + i, isInsert: false, isUpdate: false,
                isDelete: false, isSubmit: false
            })
        }
        setData([...data, ...rowData]);
    }

    const getGridData = () => {
        if (msRef.current) {
            const tableState = data
                .filter(rowNode => rowNode.sourceId)
                .map((rowNode, index) => {
                    if ((rowNode.roadType && rowNode.vehicleTypeDescription && rowNode.fuelUsage && rowNode.resourceType
                        && rowNode.distanceTraveled && rowNode.sourceDescription) &&
                        ((rowNode.yearsArray?.length > 1 && rowNode.vehicleYear) ||
                            (rowNode.yearsArray?.length === 1 && (!rowNode.vehicleYear || rowNode.vehicleYear === 'N/A')))
                    ) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            roadType: rowNode.roadType,
                            vehicleType: rowNode.vehicleType,
                            resourceType: rowNode.resourceType,
                            vehicleYear: rowNode.vehicleYear ? Number(rowNode.vehicleYear) : null,
                            fuelUsage: parseFloat(rowNode.fuelUsage),
                            distanceTraveled: parseFloat(rowNode.distanceTraveled),
                            distanceUnits: rowNode.distanceUnits,
                            units: rowNode.units,
                            fuelType: rowNode.fuelType,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            uploadedFileName: rowNode.uploadedFileName ? rowNode.uploadedFileName : null,
                            vehicleTypeDescription: rowNode.vehicleTypeDescription,
                            ethanolPercentage: rowNode.vehicleTypeDescription?.includes('Ethanol') ? Number(ethanolPercent) : null,
                            biodieselPercentage: rowNode.vehicleTypeDescription?.includes('Biodiesel') ? Number(biodieselPercent) : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;

                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }

                });
            if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return tableState.filter(ele => Object.keys(ele)?.length > 0);
        }
        return [];
    }

    const onSubmit = async () => {
        const gridData = getGridData();
        if ((gridData.some(ele => (ele?.vehicleTypeDescription?.includes('Biodiesel'))) && !biodieselPercent)) {
            toast.error('Please enter Biodiesel Percentage!');
            return
        } else if ((gridData.some(ele => (ele?.vehicleTypeDescription?.includes('Ethanol'))) && !ethanolPercent)) {
            toast.error('Please enter Ethanol Percentage!');
            return
        }
        if (error === true) {
            toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')}`)
            setRowIndex([])
            error = false;
            return
        } else {
            setMobileSourcesData(gridData);
            setSteps(0);
        }
    }

    const createCache = async (data) => {
        const tempData = {
            ...cacheData,
            mobilesourcesData: data?.filter(ele => ele?.sourceId),
            requestId: requestId,
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = async (event) => {
        if (event) {
            if (event?.colDef?.field === "vehicleTypeDescription") {
                delete event.data['vehicleYear'];
                if (event?.value?.includes('Biodiesel') && !biodieselPercent) {
                    setOpen(true)
                    setBiodiesel(true);
                    setType(1)
                }
                if (event?.value?.includes('Ethanol') && !ethanolPercent) {
                    setOpen(true)
                    setEthanol(true)
                    setType(2)
                }
                const vehicleData = vehicleTypeData?.find(ele => ele?.vehicleTypeDescription === event?.value);
                event.data.fuelType = vehicleData?.fuelType;
                event.data.vehicleType = vehicleData?.vehicleType;
                await getVehicleYearData(vehicleData?.vehicleType, vehicleData?.fuelType)
                    .then((res) => {
                        event.data.yearsArray = res;
                        setDeleteLoading(false);
                    })
            }
            if (event.value) {
                if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                    event.data.isUpdate = true;
                    event.data.isInsert = false;
                } else if(event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                    event.data.id = uuidGenerator();
                    event.data.isInsert = true;
                    event.data.isUpdate = false;
                } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                    event.data.isInsert = true;
                    event.data.isUpdate = false;
                }
            }
        }
        await handleRowChange()
        const updatedArray = data?.map((item) => {
            if(item?.vehicleTypeDescription?.includes('Biodiesel')) {
                item.biodieselPercentage = biodieselPercent ?  Number(biodieselPercent) : null
            } else if(item?.vehicleTypeDescription?.includes('Ethanol')) {
                item.ethanolPercentage = ethanolPercent ? Number(ethanolPercent) : null
            }
            return item
        })
        createCache(data?.filter(ele => ele?.sourceId))
    }

    const handleEdit = (type) => {
        type === 1 ? setType(1) : setType(2);
        setOpen(true)
    }

    const handleRowChange = () => {
        if (data?.some(ele => ele?.vehicleTypeDescription?.includes('Biodiesel'))) {
            setBiodiesel(true)
            
        } else if (data?.some(ele => ele?.vehicleTypeDescription?.includes('Biodiesel')) === false) {
            setBiodieselPercent()
            setBiodiesel(false)
        }
        if (data?.some(ele => ele?.vehicleTypeDescription?.includes('Ethanol'))) {
            setEthanol(true)
        } else if (data?.some(ele => ele?.vehicleTypeDescription?.includes('Ethanol')) === false) {
            setEthanolPercent()
            setEthanol(false)
        }
        
    }

    // useEffect(() => {
    //    onCellValueChanged()
    // }, [biodieselPercent, ethanolPercent])

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Mobile Source Fuel Combustion</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            {
                ((Number(biodieselPercent) > 0 || Number(ethanolPercent) > 0) || (isBiodiesel || isEthanol)) && <div className="mtop-1 mobile-diesel-percent">
                    {
                        (Number(biodieselPercent) > 0 || isBiodiesel) && <div className="d-flex">
                            <Label>Biodiesel Percent</Label>
                            <div className="ml-0-25 input-percent w-6">
                                <InputGroup>
                                    <input
                                        className="datepicker-control w-100 percent-padding bg-f5f5f5"
                                        name="biodiesel"
                                        type="number"
                                        disabled={true}
                                        min={1}
                                        max={100}
                                        value={biodieselPercent}
                                    />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </div>
                            <GrEdit className="edit-percent" onClick={() => handleEdit(1)} />
                        </div>
                    }
                    {
                        (Number(ethanolPercent) > 0 || isEthanol) && <div className="d-flex">
                            <Label>Ethanol Percent </Label>
                            <div className="ml-0-25 input-percent w-6">
                                <InputGroup>
                                    <input
                                        className="datepicker-control w-100 percent-padding bg-f5f5f5"
                                        name="biodiesel"
                                        type="number"
                                        disabled={true}
                                        min={1}
                                        max={100}
                                        value={ethanolPercent}
                                    />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </div>
                            <GrEdit className="edit-percent" onClick={() => handleEdit(2)} />
                        </div>
                    }

                </div>
            }

            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div className="mtop-1" style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={data?.filter(ele => ele?.isDelete === false)}
                        columnData={columnData}
                        agRef={msRef}
                        onCellValueChanged={onCellValueChanged}
                    />
                    <div className="ag-custom-footer">
                        <div className="d-flex ">
                            <button className="add-row-button" onClick={addRows}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                            <input
                                type="number"
                                className="ml-1 input-number"
                                value={noOfRow}
                                onChange={(e) => addingRows(e.target.value)}
                            />
                            <h5 className="ml-1">rows</h5>
                        </div>
                    </div>
                </div>
            </div>
            {
                open === true && <PopupPercentage
                    value={open}
                    setOpenModal={setOpen}
                    type={type}
                    biodiesel={biodieselPercent}
                    setBiodiesel={setBiodieselPercent}
                    ethanol={ethanolPercent}
                    setEthanol={setEthanolPercent}
                />
            }
        </Fragment>
    )
}

export default MobileSources;


const PopupPercentage = ({ value, setOpenModal, type, biodiesel, setBiodiesel, ethanol, setEthanol }) => {
    const [open, setOpen] = useState(value);
    const [bioPercentage, setBioPercentage] = useState(biodiesel);
    const [currentEthanolPercentage, setCurrentEthanolPercentage] = useState(ethanol);
    const [err, setErr] = useState(false);

    const handleBiodiesel = (value) => {
        setBioPercentage(value)
        setBiodiesel(value)
    }

    const handleEthanol = (value) => {
        setCurrentEthanolPercentage(value)
        setEthanol(value)
    }

    const onClose = () => {
        if ((type === 1 && bioPercentage > 0) || (type === 2 && currentEthanolPercentage > 0)) {
            setErr(false)
            setOpen(false);
            setOpenModal(false);
        } else {
            setErr(true)
            toast.error('Please enter value')
        }
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='sm'>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Details</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {
                            type === 1 && <Col lg={12}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Biodiesel Percentage</label>
                                        <input
                                            className={`form-control`}
                                            type="number"
                                            placeholder="0"
                                            min={1}
                                            max={100}
                                            name='biodiesel'
                                            value={bioPercentage}
                                            onChange={(e) => handleBiodiesel(e.target.value)}
                                        />
                                    </FormGroup>
                                    {err && <span className="text-danger">Required and it should be between 1 to 100</span>}
                                </div>
                            </Col>
                        }
                        {
                            type === 2 && <Col lg={12}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Ethanol Percentage</label>
                                        <input
                                            className={`form-control`}
                                            type="number"
                                            placeholder="0"
                                            min={1}
                                            max={100}
                                            name='ethanol'
                                            value={currentEthanolPercentage}
                                            onChange={(e) => handleEthanol(e.target.value)}
                                        />
                                    </FormGroup>
                                    {err && <span className="text-danger">Required and it should be between 1 to 100</span>}
                                </div>
                            </Col>
                        }
                    </Row>

                </ModalBody>
            </Modal>
        </Fragment>
    )
}

const gridData = [
    {
        vehicleType: ''
    }
]