import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button, Col, FormGroup, Row } from "reactstrap";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { currencySymbol } from "../../../api/currencyData";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import moment from "moment";
import { Trash2 } from "react-feather";

const StakeholderInterest = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [stakeholderGroup, setStakeholderGroup] = useState()
    const [stakeholderList, setStakeholderlist] = useState([...Array(4)].map((_, index) => ({ serialNo: index + 1 })));
    const [consultationDetails, setConsultationDetails] = useState();
    const [consultationSupport, setConsultationSupport] = useState()
    const [stakholderPolicy, setStackholderPolicy] = useState()
    const [stakeholderEngagement, setStakeholderEngagement] = useState()

    const getStakeholderEngagementData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/stakeholderEngagementData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setStakeholderlist(res.json)
            }, (err) => {
                if (err.status === 404) {
                    setStakeholderlist([...Array(4)].map((_, index) => ({ serialNo: index + 1 })))
                }
            })
    }

    const getAttributesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter(ele => ele.groupName === 'stakeholderInterestResponsiveness');
                setStakeholderGroup(data?.find(ele => ele.key === 'Q24')?.value);
                setConsultationDetails(data?.find(ele => ele.key === 'Q25')?.value);
                setConsultationSupport(data?.find(ele => ele.key === 'Q26')?.value);
                setStackholderPolicy(data?.find(ele => ele.key === 'Q27')?.value);
                setStakeholderEngagement(data?.find(ele => ele.key === 'Q28')?.value);
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (financialYear) {
            getStakeholderEngagementData()
            getAttributesData()
        }
    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
        updatedRowData = stakeholderList?.map((row, index) => {
            return (index == params.node.rowIndex) ? { serialNo: row?.serialNo } : { ...row }
        });
        setStakeholderlist(updatedRowData)
    }

    const stakeholderColumns = [
        {
            headerName: 'Stakeholder Group  ',
            field: 'stakeholderGroup',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: 'Vulnerable &  Marginalized Group',
            field: 'isVulnerableGroup',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Communication Channel',
            field: 'communicationChannels',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Email', 'SMS', 'NewsPaper', 'Pamphlets', 'Advertisements',
                    'Community Meetings', 'Notice Board', 'Website', 'Others']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Frequency of engagement',
            field: 'frequencyOfEngagement',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Annually', 'Half Yearly', 'Quarterly', 'Others']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Engagement Purpose & Concerns',
            field: 'purposeScopeEngagement',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if (params.data.hasOwnProperty('stakeholderGroup') ||
        //             params.data.hasOwnProperty('isVulnerableGroup') ||
        //             params.data.hasOwnProperty('communicationChannels') ||
        //             params.data.hasOwnProperty('frequencyOfEngagement') ||
        //             params.data.hasOwnProperty('purposeScopeEngagement')
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)} />
        //                 </div>
        //             )
        //     }
        // }
    ]

    const onSubmit = async () => {
        const bodyData = {
            financialYear: financialYear,
            stakeholderEngagementData: stakeholderList?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            attributesKeyValueData: [
                {
                    serialNo: 5,
                    groupName: 'stakeholderInterestResponsiveness',
                    key: 'Q24',
                    value: stakeholderGroup
                },
                {
                    serialNo: 5,
                    groupName: 'stakeholderInterestResponsiveness',
                    key: 'Q25',
                    value: consultationDetails
                },
                {
                    serialNo: 5,
                    groupName: 'stakeholderInterestResponsiveness',
                    key: 'Q26',
                    value: consultationSupport
                },
                {
                    serialNo: 5,
                    groupName: 'stakeholderInterestResponsiveness',
                    key: 'Q27',
                    value: stakholderPolicy
                },
                {
                    serialNo: 5,
                    groupName: 'stakeholderInterestResponsiveness',
                    key: 'Q28',
                    value: stakeholderEngagement
                },
            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res.json)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(4)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }
    return (
        <Fragment>
            <h3>Stakeholder Interest Responsiveness</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    Describe the processes for identifying key stakeholder groups of the entity
                </h5>
                <div className="col-6">
                    <textarea className="form-control ml-1" value={stakeholderGroup} rows={3}
                        onChange={(e) => setStakeholderGroup(e.target.value)} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>Key Stakeholder Groups Identified and Their Engagement Frequency</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={stakeholderList}
                        columnData={stakeholderColumns}
                    />
                </div>
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    Provide the processes for consultation between stakeholders and the Board on economic, environmental, and
                    social topics or if consultation is delegated, how is feedback from such consultations provided to the Board
                </h5>
                <div className="col-6">
                    <textarea className="form-control ml-1" value={consultationDetails} rows={3}
                        onChange={(e) => setConsultationDetails(e.target.value)} />
                </div>
            </div>
            <h5 className="mtop-1">Whether stakeholder consultation is used to support the identification and management of environmental, and social topics (Yes / No).
                If so, provide details of instances as to how the inputs received from stakeholders on these topics were incorporated into policies and activities of the entity</h5>
            <div className="d-flex">
                <div className="col-6">
                    <select className="form-select" value={consultationSupport} onChange={(e) => setConsultationSupport(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div className="col-6">
                    {
                        consultationSupport === 'Yes' && <textarea className="form-control ml-1 mtop-1" value={stakholderPolicy} rows={3}
                            onChange={(e) => setStackholderPolicy(e.target.value)} />
                    }
                </div>
            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups
                </h5>
                <div className="col-6">
                    <textarea className="form-control ml-1" value={stakeholderEngagement} rows={3}
                        onChange={(e) => setStakeholderEngagement(e.target.value)} />
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(3)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default StakeholderInterest;