import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button } from "reactstrap";
import { policyTemplateData, principleList } from "../../Settings/settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";

const PolicyProcess = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [policyData, setPolicyData] = useState(policyTemplateData);

    const getPolicyData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/disclosureQuestionsData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempArray = policyTemplateData?.map(ele => {
                    const temp = res?.json?.find(item => item?.questionText === ele?.questionText);
                    temp.type = ele?.type;
                    return temp ? temp : ele;
                })
                tempArray?.length > 0 ? setPolicyData(tempArray?.map((item, index) => ({ ...item, serialId: index + 1 }))) : setPolicyData(policyTemplateData)
            }, (err) => {
                console.log(err)
                
            })
    }

    useEffect(() => {
        if (financialYear) {
            getPolicyData()
        }
    }, [financialYear])

    const policyColumns = [
        {
            headerName: 'S.No',
            field: 'serialId',
            editable: false,
            filter: false,
            width: 40,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Disclosure Questions',
            field: 'questionText',
            editable: false,
            filter: false,
            cellClass: 'disable-cell cell-wrap',
            autoHeight: true,
            wrapText: true,
            flex: 2.5
        },
        {
            headerName: 'P1',
            field: 'p1Set1',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            field: 'p2Set1',
            headerName: 'P2',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: 'p3Set1',
            headerName: 'P3',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: 'p4Set1',
            headerName: 'P4',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: 'p5Set1',
            headerName: 'P5',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: 'p6Set1',
            headerName: 'P6',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: 'p7Set1',
            headerName: 'P7',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: 'p8Set1',
            headerName: 'P8',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: 'p9Set1',
            headerName: 'P9',
            editable: true,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
    ]

    const onSubmit = async () => {
        const data = policyData?.map(({ type, serialId, ...item }) => item);
        const options = {
            method: 'POST',
            body: {
                financialYear: financialYear,
                disclosureQuestionsData: data?.map((item, index) => ({ ...item, serialNo: index + 1 }))
            }
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                
            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(3)
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
    }
    return (
        <Fragment>
            <h3>Policy Process</h3>
            <div className="mtop-1 company-profile">
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={policyData}
                        columnData={policyColumns}
                    />
                </div>
            </div>
            <div className="mtop-1">
                <div className="">
                    <h4>Note:</h4>
                    {
                        principleList?.map((item, index) => (
                            <p className="mb-0 ml-1">P{index + 1} - <span className="ml-1">{item}</span></p>
                        ))
                    }
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(1)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default PolicyProcess;