import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button } from "reactstrap";
import { principleList, reviewParticulars } from "../../Settings/settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";

const NgrbcReview = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [ngrbcReview, setNgrbcReview] = useState(reviewParticulars);

    const getNgrbcData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/disclosureQuestionsData/${financialYear}`)
            .then((res) => {
                const tempArray = reviewParticulars?.map(ele => {
                    const temp = res?.json?.find(item => item?.questionText === ele?.questionText);
                    return temp ? temp : ele;
                })
                tempArray?.length > 0 ? setNgrbcReview(tempArray) : setNgrbcReview(reviewParticulars)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (financialYear) {
            getNgrbcData()
        }
    }, [financialYear])

    const ngrbcColumns = [
        {
            headerName: 'Subject for Review',
            field: 'questionText',
            editable: false,
            filter: false,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,

        },
        {
            headerName: 'Indicate whether review was undertaken by Director / Committee of the Board/Any other Committee',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'P1',
                    field: 'p1Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P2',
                    field: 'p2Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P3',
                    field: 'p3Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P4',
                    field: 'p4Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P5',
                    field: 'p5Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P6',
                    field: 'p6Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P7',
                    field: 'p7Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P8',
                    field: 'p8Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P9',
                    field: 'p9Set1',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
            ]
        },
        {
            headerName: 'Frequency (Annually/Half yearly/ Quarterly/ Any other – please specify)',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'P1',
                    field: 'p1Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P2',
                    field: 'p2Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P3',
                    field: 'p3Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P4',
                    field: 'p4Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P5',
                    field: 'p5Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P6',
                    field: 'p6Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P7',
                    field: 'p7Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P8',
                    field: 'p8Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
                {
                    headerName: 'P9',
                    field: 'p9Set2',
                    editable: true,
                    filter: false,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    width: 150,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false
                },
            ]
        }
    ]

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    };

    const onSubmit = async () => {
        const data = {
            financialYear: financialYear,
            disclosureQuestionsData: ngrbcReview?.map((item, index) => ({ ...item, serialNo: 12 + index}))
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {

            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(5)
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })

    }

    return (
        <Fragment>
            <h3>Review of Responsible Business Guidelines</h3>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={ngrbcReview}
                                columnData={ngrbcColumns}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mtop-1">
                <div className="">
                    <h4>Note:</h4>
                    {
                        principleList?.map((item, index) => (
                            <p className="mb-0 ml-1">P{index + 1} - <span className="ml-1">{item}</span></p>
                        ))
                    }
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(4)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default NgrbcReview;