import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import AgGridTable from '../AgGridTable';
import completed from '../../../assets/images/completed.svg';
import dataProcess from '../../../assets/images/data-process.svg';
import rejected from '../../../assets/images/file-remove.svg';
import approved from '../../../assets/images/approved.svg';
import readyForApprove from '../../../assets/images/file-clock.svg';
import draft from '../../../assets/images/draft.svg';


const ProgressSheet = () => {
    const [progressData, setProgressData] = useState([])
    const progressRef = useRef(null);

    const statusObject = {
        'SAVE_AS_DRAFT': draft,
        'DATA_SUBMITTED': readyForApprove,
        'APPROVED': approved,
        'REJECTED': rejected,
        'STAGE_1_COMPLETE': approved,
        'STAGE_2_COMPLETE': approved,
        'STAGE_3_COMPLETE': approved,
        'STAGE_4_COMPLETE': approved,
        'STAGE_1_ERROR': approved,
        'STAGE_2_ERROR': approved,
        'STAGE_3_ERROR': approved,
        'STAGE_4_ERROR': approved,
        'PROCESSING_COMPLETE': approved
    }

    const statusText = {
        'SAVE_AS_DRAFT': 'Save As Draft',
        'DATA_SUBMITTED': 'Pending Approval',
        'APPROVED': 'Approved',
        'REJECTED': 'Rejected',
        'STAGE_1_COMPLETE': 'Approved',
        'STAGE_2_COMPLETE': 'Approved',
        'STAGE_3_COMPLETE': 'Approved',
        'STAGE_4_COMPLETE': 'Approved',
        'STAGE_1_ERROR': 'Approved',
        'STAGE_2_ERROR': 'Approved',
        'STAGE_3_ERROR': 'Approved',
        'STAGE_4_ERROR': 'Approved',
        'PROCESSING_COMPLETE': 'Approved'
    }

    const getProgressData = async () => {
        await fetchWrapper(`${API_URL}/ghgemissionsdata/getDataCaptureStatus`)
            .then((res) => {
                console.log(res)
                setProgressData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getProgressData()
    }, [])

    const columnData = [
        {
            headerName: 'Location Name',
            field: 'locationName',
            filter: true,
            flex: 3,
            sortable: false,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Year',
            field: 'year',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
        },
        {
            headerName: 'January',
            field: 'january',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.january],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data.january]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'February',
            field: 'february',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.february],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.february]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'March',
            field: 'march',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.march],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.march]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'April',
            field: 'april',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.april],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.april]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'May',
            field: 'may',
            filter: false,
            flex: 1.5,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.may],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.may]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'June',
            field: 'june',
            filter: false,
            flex: 1.5,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.june],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.june]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'July',
            field: 'july',
            filter: false,
            flex: 1.5,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.july],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.july]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'August',
            field: 'august',
            filter: false,
            flex: 1.5,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.august],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.august]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'September',
            field: 'september',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.september],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.september]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'October',
            field: 'october',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.october],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.october]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'November',
            field: 'november',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.november],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.november]} height={25} width={25} />
                    </div>
                )

            }
        },
        {
            headerName: 'December',
            field: 'december',
            filter: false,
            flex: 2,
            sortable: false,
            suppressMovable: true,
            tooltipValueGetter : (params) => statusText[params.data.december],
            cellRenderer: (params) => {
                return (
                    <div>
                        <img src={statusObject[params.data?.december]} height={25} width={25} />
                    </div>
                )

            }
        },
    ]

    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs mainTitle="Progress" />
                <Card>
                    <CardBody>
                        
                        <div className="location-table">
                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={columnData}
                                    tableData={progressData}
                                    agRef={progressRef}
                                // onGridReady={onGridReady}
                                />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}

export default ProgressSheet;