import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import { defaultUserType } from "../../api/helper";
import MapComponent from "../MarkerMap/MapComponent";

const LocationForm = ({ title, action, navigateUrl, cancel, preData, setAdd }) => {
    const [loading, setLoading] = useState(false)
    const [countryList, setCountryList] = useState();
    const [groupList, setGroupList] = useState();
    const [stateList, setStateList] = useState();
    const [locationType, setType] = useState();
    const [data, setData] = useState(action === 'Edit' ? preData : '')
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({ defaultValues: data });
    const navigate = useNavigate();
    const [countryId, setCountryId] = useState(preData?.countryId);
    const [stateId, setStateId] = useState(preData?.stateId);
    const [region, setRegion] = useState(preData?.region);
    const [zipcode, setZipcode] = useState(preData?.zipcode);
    const [address, setAddress] = useState();
    const [stateName, setStateName] = useState();

    const [markerPosition, setMarkerPosition] = useState({
        lat: 37.7749, // Set your initial latitude
        lng: -122.4194, // Set your initial longitude
    });

    const mapStyles = {
        width: '100%',
        height: '400px'
    }

    const loadCountry = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                if (res.status === 200) {
                    setCountryList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const loadGroups = async () => {
        await fetchWrapper(`${API_URL}/group`)
            .then((res) => {
                if (res.status === 200) {
                    setGroupList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const loadLocationType = async () => {
        await fetchWrapper(`${API_URL}/locationtype`)
            .then((res) => {
                if (res.status === 200) {
                    setType(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        loadCountry();
        loadGroups();
        loadLocationType();
    }, []);

    const handleCountry = async (id) => {
        setCountryId(id)
        const countryData = countryList?.find(ele => ele?.id == id);
        setRegion(countryData?.region);
        setValue('region', countryData?.region)
        handleAddress()
        await fetchWrapper(`${API_URL}/state/country/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    setStateList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const handleGoeCoordinates = (val) => {
        let geoData = val.split(',');
        setMarkerPosition({
            lat: Number(geoData[0]),
            lng: Number(geoData[1])
        })
    }


    const onSubmit = async (data) => {
        setLoading(true)
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/location`
            message = 'Location Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/location/${data.locationId}`
            message = 'Location Updated Successfully!'
        }
        data.geoCoordinates = `${markerPosition.lat},${markerPosition.lng}`;
        data.geoLocation = `https://www.google.com/maps?q=${markerPosition.lat},${markerPosition.lng}`
        const options = {
            method: method,
            body: data
        }

        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    action === 'Add' && setAdd(true)
                    toast.success(message)
                    setLoading(false)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Location Already Exist..')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    const handleZipcode = (value) => {
        setZipcode(value);
        setValue('zipCode', value);
        handleAddress();
    }

    const handleAddress = () => {
        const stateData = stateList?.find(ele => ele?.id == getValues('stateId'));
        const countryData = countryList?.find(ele => ele?.id == getValues('countryId'));
        setAddress(`${getValues('addressLine1')},${getValues('city')},${stateData?.name},${countryData?.name}`)
        console.log(stateData, stateData?.description)
    }

    const handleState = (val) => {
        setStateId(val)
        const stateData = stateList?.find(item => item.id === val);
        setStateName(stateData?.name)
        setValue('stateId', val)
        handleAddress()
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        handleCountry(preData?.countryId)
        setStateId(preData?.stateId)
        setValue('stateId', preData?.stateId);
        if (preData?.geoCoordinates) {
            handleGoeCoordinates(preData?.geoCoordinates)
        }
        reset(preData)
    }, [preData])



    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    {title && <H3 attrH3={{ className: 'mtop-2' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 bs-none'}>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Group Name<span className='required'>*</span></Label>
                                            <select className="form-select" name="groupName"
                                                {...register('groupId', { required: true })}
                                            >
                                                <option value="">-Select Group-</option>
                                                {
                                                    groupList?.length > 0 && groupList.map((item) => {
                                                        return (
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.groupId && 'Group is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Location Type <span className='required'>*</span></Label>
                                            <select className="form-select" name="locationType"
                                                {...register('locationTypeId', { required: true })}
                                            >
                                                <option value="">-Select Location Type-</option>
                                                {
                                                    locationType?.length > 0 && locationType.map((item) => {
                                                        return (
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.locationTypeId && 'Location Type is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label for="exampleEmail">Location Code</Label>
                                            <input
                                                className="form-control"
                                                name="locationCode"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.locationCode}
                                                {...register('locationCode')}
                                            />
                                            <span className="text-danger">{errors.locationCode && 'Location Name is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label for="exampleEmail">Location Name <span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="locationName"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.locationName}
                                                {...register('locationName', { required: true })}
                                            />
                                            <span className="text-danger">{errors.locationName && 'Location Name is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label for="exampleEmail">Address Line 1 <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="addressLine1"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.addressLine1}
                                                {...register('addressLine1', { required: true })}
                                                onChange={handleAddress}
                                            />
                                            <span className="text-danger">{errors?.addressLine1 && 'Address is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label >Address Line 2</Label>
                                            <input
                                                className="form-control"
                                                name="addressLine2"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.addressLine2}
                                                {...register('addressLine2')}
                                                onChange={handleAddress}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Area (sqft)</Label>
                                            <input
                                                className="form-control"
                                                name="area"
                                                placeholder=""
                                                type="number"
                                                defaultValue={data?.area}
                                                {...register('area')}
                                            />
                                            <span className="text-danger">{errors.area?.type === 'required' && 'Area is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>City<span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="city"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.city}
                                                {...register('city', { required: true })}
                                                onChange={handleAddress}
                                            />
                                            <span className="text-danger">{errors.city && 'City is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Zipcode<span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="zipcode"
                                                placeholder=""
                                                type="text"
                                                defaultValue={zipcode}
                                                {...register('zipCode', { required: true, maxLength: 10, minLength: 3 })}
                                                onChange={(e) => handleZipcode(e.target.value)}
                                            />
                                            <span className="text-danger">{errors.zipCode?.type === 'required' && 'Zipcode is Required'}</span>
                                            <span className="text-danger">{errors.zipCode?.type === 'maxLength' && 'Zipcode should be less than 10 characters'}</span>
                                            <span className="text-danger">{errors.zipCode?.type === 'minLength' && 'Zipcode should be more than 3 characters'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Country <span className='required'>*</span></Label>
                                            <select className="form-select" name="country" value={countryId}
                                                {...register('countryId', { required: true })}
                                                onChange={(e) => handleCountry(e.target.value)}
                                            >
                                                <option value="">-Select Country-</option>
                                                {
                                                    countryList?.length > 0 && countryList.map((item) => {
                                                        return (
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.countryId && 'Country is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>State<span className='required'>*</span></Label>
                                            <select className="form-select" name="state"
                                                value={stateId}
                                                {...register('stateId', { required: true })}
                                                onChange={(e) => handleState(e.target.value)}
                                            >
                                                <option value="">-Select State-</option>
                                                {
                                                    stateList?.length > 0 && stateList?.map((i) => {
                                                        return <option value={i.id}>{i.name}</option>
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.stateId && 'State is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Region</Label>
                                            <input
                                                className="form-control"
                                                name="region"
                                                placeholder=""
                                                type="text"
                                                disabled={true}
                                                defaultValue={region}
                                                {...register('region')}
                                            />
                                            {console.log(errors)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <MapComponent zipcode={address} position={markerPosition} setMarker={setMarkerPosition} styles={mapStyles} />
                                <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={`${action === "Add" ? action : 'Updat'}ing`} color="primary" /> : <Button color="primary" >{action === "Add" ? action : 'Update'}</Button>
                                        }
                                        <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default LocationForm;