import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import AgGridTable from '../AgGridTable';
import moment from 'moment';
import { month } from '../../Constant';

const ApprovalQueue = () => {
    const [approvalList, setApprovalList] = useState([]);
    const type = useParams()?.type;
    const approvalRef = useRef(null);

    const getAllList = async () => {
        await fetchWrapper(`${API_URL}/ghgemissionsdata/getAllRequests`)
            .then((res) => {
                console.log(res.json)
                setApprovalList(res.json?.filter(ele => ['DATA_SUBMITTED', 'REJECTED', 'APPROVED']?.includes(ele?.status)))
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAllList()
    }, [])

    const columnData = [
        {
            headerName: 'Location Name',
            filter: false,
            flex: 1,
            sortable: false,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
            cellRenderer: (params) => {
                if(type === 'approval') {
                    return (
                        <div>
                            <Link className='f-w-600' to={`${process.env.PUBLIC_URL}/data-capture/${params.data.requestId}/review`}>
                                {params.data.locationName}
                            </Link>
                        </div>
                    )
                } else {
                    return (
                        <div>{params.data.locationName}</div>
                    )
                }
            },
        },
        {
            headerName: 'Period',
            filter: true,
            flex: 1,
            sortable: false,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.uploadPeriod === 'YEAR' ? params.data.year : params.data.uploadPeriod === 'MONTH' ?
                    params.data.month + ', ' + params.data.year : 'Q' + params.data.quarter + ', ' + params.data.year;
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            field: 'status',
            filter: true,
            flex: 1,
            valueGetter: (params) => {
                return params.data.status === 'DATA_SUBMITTED' ? 'Pending Approval' : params.data.status.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
            },
            sortable: false,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Request Submitted By',
            field: 'requestSubmittedBy',
            filter: true,
            flex: 1.25,
            sortable: false,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Request Date Time (UTC)',
            field: 'requestSubmittedDate',
            filter: false,
            flex: 1.5,
            sortable: false,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.requestSubmittedDate ? moment(params.data.requestSubmittedDate).format('DD MMM, YYYY hh:MM A') : ''
            }
        },
        {
            headerName: 'Approved / Rejected Date Time (UTC)',
            field: 'approvedDate',
            filter: false,
            flex: 1.5,
            valueGetter: (params) => {
                return (params.data.approvedDate && params.data.status === 'APPROVED') ? moment(params.data.approvedDate).format('DD MMM, YYYY hh:MM A') : (params.data.rejectedDate && params.data.status === 'REJECTED') ? moment(params.data.rejectedDate).format('DD MMM, YYYY hh:MM A') : '' 
            },
            sortable: false,
            suppressMovable: true
        },
    ]

    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs mainTitle={type === 'approval' ? "Approval List" : "Submit List"} />
                <Card>
                    <CardBody>
                        <div className="location-table">
                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={columnData}
                                    tableData={approvalList}
                                    agRef={approvalRef}
                                // onGridReady={onGridReady}
                                />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}

export default ApprovalQueue;