

const config = require('./AppConfig.json');

// Auth0
export const auth0 = ({
    domain: config.auth0.domain,
    clientId: config.auth0.clientID,
    redirectUri: config.auth0.redirectUri
});

// Jwt
export const Jwt_token = config.jwt_token;

// export const API_URL = 'https://api.green-carbon.ai';

//export const API_URL = ' https://ivsdtjth1m.execute-api.us-east-2.amazonaws.com/dev';

export const API_URL = process.env.REACT_APP_ENV === 'prod'? 'https://api.greencarbon.ai': 
                            (process.env.REACT_APP_ENV === 'demo'? 'https://demo.api.greencarbon.ai':
                                (process.env.REACT_APP_ENV === 'dev'? 'https://dev.api.greencarbon.ai':
                                    'https://dev.api.greencarbon.ai'));


// Scope of AAD app. Use the below configuration to use all the permissions provided in the AAD app through Azure portal.
// Refer https://aka.ms/PowerBIPermissions for complete list of Power BI scopes
export const scopes = ["https://analysis.windows.net/powerbi/api/Report.Read.All"];


