import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody
} from "reactstrap";
import { Breadcrumbs, H3, H5 } from "../../../AbstractElements";
import HeaderCard from '../HeaderCard';
import ChangePassword from "./ChangePassword";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router-dom";
import { defaultUserType } from '../../api/helper';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { USER_DETAILS } from "../../Constant";
import { useDispatch, useSelector } from "react-redux";
import defaultAvatar from '../../../assets/images/user/default-avatar.png';
import defaultLogo from '../../../assets/images/default-logo.png';

const EditProfile = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ defaultValues: data });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true)
    const dispatch = useDispatch();
    const logoRef = useRef(null);
    const userData = useSelector(state => state?.user?.userDetails);
    const [orgDetails, setOrgDetails] = useState({});

    const handleButtonClick = () => {
        logoRef.current.click();
    };

    const getOrgDetails = async() => {
        await fetchWrapper(`${API_URL}/users/orgDetails`)
        .then((res) => {
            setOrgDetails(res.json[0])
        }, (err) => {
            console.log(err)
        })
    }

    const handleFileChange = async (event) => {

        if (sessionStorage.getItem('userType') === 'ASO_ADMIN') {
            const file = event.target.files[0];
            let result;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                var img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    var height = img.naturalHeight;
                    var width = img.naturalWidth;
                    if (height > 48 && width > 48) {
                        console.log(false)
                        result = false;
                        toast.error('Image should be in 48X48 or 96X48 pixel')
                        return
                    } else {
                        console.log(true)
                        result = true;
                    }
                }
            }
            if (file?.size > 524288) {
                toast.error('The File Size should be lessthan 512 KB')
                return
            } else {
                setLoading(true)
                const authToken = sessionStorage.getItem('accessToken')
                let data = new FormData();
                data.append('logo', file);
                data.append('orgId', userData?.org)
                const options = {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${authToken}`
                    },
                    body: data
                }
                await fetchWrapper(`${API_URL}/organization/uploadLogo`, options)
                    .then((res) => {
                        console.log(res)
                        setLoading(false)
                        toast.success('Logo Uploaded Successfully!')
                    }, (err) => {
                        console.log(err)
                        setLoading(false)
                        toast.error('Something went wrong')
                    })
            }
            console.log(result)
        }

    };

    const loadData = async (data) => {
        await fetchWrapper(`${API_URL}/users/userProfile`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setData(res.json)
                    dispatch({
                        type: USER_DETAILS,
                        payload: res.json
                    })
                    setValue('firstName', res.json.firstName)
                    setValue('lastName', res.json.lastName)
                    setValue('email', res.json.email)
                    setValue('phone', res.json.phone)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const handleProfile = async (data) => {
        setLoading(true)
        const options = {
            method: 'PUT',
            body: {
                first_name: data.firstName,
                last_name: data.lastName,
                phone: data.phone
            }
        }
        await fetchWrapper(`${API_URL}/users/update/profile`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setLoading(false)
                    toast.success('Profile Updated Successfully!')
                    loadData()
                }
            }, (error) => {
                console.log(error)
                if (error.status === 500) {
                    setLoading(false)
                    toast.error('Something went wrong!')
                }
            })
    }

    useEffect(() => {
        loadData()
        getOrgDetails()
    }, [])

    const handleCancel = () => {
        const userType = sessionStorage.getItem('userType')
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/projects`)
    }

    const uploadLogo = async (e) => {

    }

    return (
        <Fragment>
            <LayoutLoader />
            {open && <ChangePassword value={open} setOpenModal={setOpen} />}
            <Container fluid={true}>
                <Row>
                    <Col lg='12'>
                        <div className="d-flex mtop-1">
                            <Breadcrumbs parent={'Profile'} title="Edit" mainTitle="Profile Settings" />
                            {/* <div className="text-right  action_button mr-13">
                                <div className="link action_button" onClick={handleOpen}>
                                    {"Change Password"}
                                </div>
                            </div> */}
                        </div>
                        <div className="d-flex justify-center">
                            <Card className="">
                                <CardBody>
                                    <div>
                                        <h3>{orgDetails?.companyName}</h3>
                                    </div>
                                    <div className="d-flex justify-evenly mtop-1 mb-1rem">
                                        <div>
                                            <div className="d-flex justify-center">
                                                <img className="profile-pic" src={defaultAvatar} />
                                            </div>
                                            <div className="d-flex justify-center mtop-1">
                                                <Button color="primary" >Upload Picture</Button>
                                            </div>
                                        </div>
                                        {
                                            sessionStorage.getItem('userType') === 'ASO_ADMIN' && <div>
                                                <div className="d-flex justify-center">

                                                    <img className="profile-pic" src={defaultLogo} />
                                                </div>
                                                <div className="d-flex justify-center mtop-1">
                                                    <input type='file' hidden
                                                        accept='.png'
                                                        ref={logoRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                    />
                                                    <Button color="primary" onClick={handleButtonClick}>Upload Logo</Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <Form >
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>First Name <span className="text-danger">*</span></Label>
                                                    <input
                                                        name="firstName"
                                                        className="form-control"
                                                        type="text"
                                                        {...register('firstName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.firstName && 'First Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>Last Name <span className="text-danger">*</span></Label>
                                                    <input
                                                        className="form-control"
                                                        name="lastName"
                                                        type="text"
                                                        {...register('lastName', { required: true })}
                                                    />
                                                    <span className="text-danger">{errors.lastName && 'Last Name is Required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='12' >
                                                <FormGroup >
                                                    <Label>Email ID</Label>
                                                    <input
                                                        name="email"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                    />
                                                    <span className="text-danger">{errors?.email?.type === 'required' && 'Email is Required'}</span>
                                                    <span className="text-danger">{errors?.email?.type === 'pattern' && 'Invalid Email'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='12'>
                                                <FormGroup >
                                                    <Label>Phone <span className="text-danger">*</span></Label>
                                                    <input
                                                        name="phone"
                                                        className="form-control"
                                                        type="tel"
                                                        {...register('phone', { required: true, pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                                    />
                                                    <span className="text-danger">{errors?.phone?.type === 'required' && 'Phone Number is Required'}</span>
                                                    <span className="text-danger">{errors?.phone?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex">
                                                <Button color="primary" type="button" onClick={handleSubmit(handleProfile)} >Submit</Button>
                                                <Button color='secondary' className="ml-1" onClick={handleCancel}> Cancel</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default EditProfile
