import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button, FormGroup, Input, Row, Col } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";

const GovernanceOversight = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [overSightData, setOversight] = useState();
    const [directorReport, setDirectReport] = useState();
    const [sustainabilityIssues, setSustainabilityIssues] = useState();
    const [entityDetails, setEntityDetails] = useState([]);

    const getGovernanceData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/disclosureQuestionsData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setEntityDetails(res.json);
                setDirectReport(res.json?.find(ele => ele?.questionNo === 'Q1')?.entityDetails);
                setOversight(res.json?.find(ele => ele?.questionNo === 'Q2')?.entityDetails);
                setSustainabilityIssues(res.json?.find(ele => ele?.questionNo === 'Q3')?.entityDetails);
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (financialYear) {
            getGovernanceData()
        }
    }, [financialYear])

    const onSubmit = async () => {
        const data = [
            {
                ...entityDetails ? entityDetails?.find(ele => ele?.questionNo === 'Q1') : '',
                serialNo: 9,
                questionText: `Statement by director responsible for the business responsibility report, highlighting ESG related challenges,
                    targets and achievements (listed entity has flexibility regarding the placement of this disclosure)`,
                entityDetails: directorReport,
                questionNo: 'Q1'
            },
            {
                ...entityDetails ? entityDetails?.find(ele => ele?.questionNo === 'Q2') : '',
                serialNo: 10,
                questionText: `Details of the highest authority responsible for implementation and oversight
                    of the Business Responsibility policy (ies)`,
                entityDetails: overSightData,
                questionNo: 'Q2'
            },
            {
                ...entityDetails ? entityDetails?.find(ele => ele?.questionNo === 'Q3') : '',
                serialNo: 11,
                questionText: `Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability
                    related issues? (Yes / No). If yes, provide details.`,
                entityDetails: sustainabilityIssues,
                questionNo: 'Q3'
            }
        ]
        const options = {
            method: 'POST',
            body: {
                financialYear: financialYear,
                disclosureQuestionsData: data
            }
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {

            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(4)
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
    }
    return (
        <Fragment>
            <h3>Governance Oversight</h3>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    Statement by director responsible for the business responsibility report, highlighting ESG related challenges,
                    targets and achievements (listed entity has flexibility regarding the placement of this disclosure)
                </h5>
                <textarea className="form-control ml-1" value={directorReport} rows={3}
                    onChange={(e) => setDirectReport(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    Details of the highest authority responsible for implementation and oversight
                    of the Business Responsibility policy (ies)
                </h5>
                <textarea className="form-control ml-1" rows={3} value={overSightData}
                    onChange={(e) => setOversight(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability
                    related issues? (Yes / No). If yes, provide details.
                </h5>
                <textarea className="form-control ml-1" rows={3} value={sustainabilityIssues}
                    onChange={(e) => setSustainabilityIssues(e.target.value)} />
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(2)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default GovernanceOversight;