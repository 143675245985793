import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { MenuItem } from '../../Sidebar/Menu';
import { Search } from 'react-feather';
import { Col, Input } from 'reactstrap';
import CustomizerContext from '../../../../Common/_helper/Customizer';
import ListOfMenu from '../RightBar/Bookmark/ListOfMenu';
import EmpltyClass from '../RightBar/Bookmark/EmptyClass';
import { H3 } from '../../../../AbstractElements';
import { useSelector } from 'react-redux';
import hand from '../../../../assets/images/hand.gif';
import moment from 'moment';
import { capitalize } from '../../../api/helper';
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { FaBahai } from "react-icons/fa";

const Searchbar = () => {
    const mainmenu = MenuItem.ASM_ADMIN;
    const [searchValue, setSearchValue] = useState('');
    const [searchIcon, setSearchIcon] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const { setIsClose } = useContext(CustomizerContext);
    const [IsOpen, setIsOpen] = useState(false);
    const userDetails = useSelector(state => state?.user?.userDetails);
    const [message, setMessage] = useState([]);
    const [scrollDuration, setScrollDuration] = useState();
    const [isNotification, setNotification] = useState(false);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setSearchValue('');
            setSearchResult([]);
            setIsClose(false);
        }
    }, []);
    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction, searchValue]);

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            setSearchIcon(true);
        } else {
            setSearchIcon(false);
        }
    };
    const addFix = () => {
        setSearchToggle(true);
    };
    const removeFix = useCallback(() => {
        setSearchValue('');
        setSearchToggle(false);
        setIsClose(false);
    }, []);

    useEffect(() => {
        if (!IsOpen) {
            setSearchValue('');
            setSearchToggle(false);
            setIsClose(false);
        }
    }, [IsOpen])

    const handleSearchKeyword = (keyword) => {
        keyword ? addFix() : removeFix();
        const items = [];
        setSearchValue(keyword);
        mainmenu.map(menuItems => {
            menuItems.Items.filter(mItems => {
                if (mItems.title.toLowerCase().includes(keyword) && mItems.type === 'link') {
                    items.push(mItems);
                }
                if (!mItems.children) return false;
                mItems.children.filter(subItems => {
                    if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
                        subItems.icon = mItems.icon;
                        items.push(subItems);
                    }
                    if (!subItems.children) return false;
                    subItems.children.filter(suSubItems => {
                        if (suSubItems.title.toLowerCase().includes(keyword)) {
                            suSubItems.icon = mItems.icon;
                            items.push(suSubItems);
                        }
                        return suSubItems;
                    });
                    return subItems;
                });
                checkSearchResultEmpty(items);
                setSearchResult(items);
                return mItems;
            });
            return menuItems;
        });
    };
    const fetchData = async () => {
        await fetchWrapper(`${API_URL}/aqitickermessage`)
            .then((res) => {
                if (res.json?.length > 0) {
                    const getMessaage = res.json?.map(item => item.message);
                    const news = [
                        'Carbon Credit, ESG Management, monetization and tokenization Platform',
                        'ML Based for optimizing Energy Consumption with autonomous capabilities',
                        'ML based Platform for AQI Descriptive & Predictive Analytics',
                        'One Shop Stop !!'
                    ]
                    getMessaage?.length > 0 ? setMessage([...getMessaage]) : setMessage([...news]);
                    const textLength = getMessaage?.length > 0 ? getMessaage.reduce((total, str) => total + str.length, 0)
                        : news.reduce((total, str) => total + str.length, 0);
                    let duration = 1 + (textLength * 0.1)
                    setScrollDuration(duration)
                    setNotification(true)
                } else {
                    setNotification(false)
                }

            }, (err) => {
                console.log(err)

            })
    }

    useEffect(() => {
        
        // if (['ASO_ADMIN', 'ASO_USER']?.includes(sessionStorage?.getItem('userType'))) {
        //     // fetchData();
        //     // const intervalId = setInterval(fetchData, 60 * 1000);

        //     // Cleanup function to clear interval on component unmount
        //     return () => clearInterval(intervalId);
        // }

    }, [])
    return (
        <Fragment>
            <Col className="left-header horizontal-wrapper ps-0 w-50">
                {
                    isNotification && ['ASO_ADMIN', 'ASO_USER'].includes(sessionStorage.getItem('userType')) && <div className="scrolling-alert-container d-flex">
                        <div className='news-title'>
                            <h4 className='f-w-500 f-s-16 mb-0'>Notifications</h4>
                        </div>
                        <div className="scrolling-alert-news" style={{ animationDuration: `${scrollDuration}s` }}>
                            <div className="news-item">
                                <div className="d-flex align-center gap-2">
                                    {
                                        message?.map((item, index) => {
                                            return (
                                                <div className='d-flex'>
                                                    {index !== 0 && <i class="fa-solid fa-burst fa-rotate-180 ml-1 mr-1" style={{ color: '#FFD43B' }}></i>}
                                                    <h4 className='f-w-500 f-s-16 mb-0'>{item}</h4>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }

            </Col>
        </Fragment >
    );
};
export default Searchbar;