import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import DataTableComponent from '../DataTable/DataTableComponent';
import { Eye, PlusCircle, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AddSubOrganization from './AddSubOrganization';
import { useSelector } from 'react-redux';
import { defaultUserType } from '../../api/helper';
import { BsFileEarmarkBarGraph } from 'react-icons/bs';
import AgGridTable from '../AgGridTable';
import { FaKhanda } from "react-icons/fa";
import ReactCountryFlag from "react-country-flag";
import { GiGearHammer } from "react-icons/gi";

const SubOrganizationList = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [orgList, setOrgList] = useState([])
    const [loading, setLoading] = useState(false);
    const [addStatus, setAddStatus] = useState(false);
    const userType = useSelector(state => state?.user?.userType);

    const orgRef = useRef(null);

    const defaultOrgType = {
        "ASSET_OWNER": 'Asset Owner',
        "ASSESSOR": 'Assessor',
        "ASSET_MANAGER": 'Asset Manager'
    }

    const loadData = async () => {
        orgRef?.current?.api?.showLoadingOverlay();
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/subOrgs`, options)
            .then(res => {
                console.log(res)
                orgRef?.current?.api?.hideOverlay();
                setOrgList(res.json)
            })
            .catch((err) => {
                orgRef?.current?.api?.hideOverlay();
                console.log(err)
            })
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/organization/status/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.isactive === false) {
                        toast.error('User has been disabled!')
                    } else {
                        toast.success('User has been enabled!')
                    }
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const columnData = [
        {
            headerName: 'Company Name',
            field: 'companyName',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Address',
            field: 'address',
            flex: 2,
            filter: true,
            valueGetter: (params) => {
                return `${params.data.address}, ${params.data.city}, ${params.data.state}`
            },
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Country',
            cellRenderer: (params) => (
                <ReactCountryFlag
                    countryCode={params.data.countryCode === 'UAE' ? 'UA' : params.data.countryCode}
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                    }}
                    title={params.data.countryName}
                />
            ),
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'Ownership Control',
            flex: 1.5,
            filter: true,
            cellRenderer: (params) => (
                <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ownership-history/${params.data.id}/${params.data.companyName}`}>
                    <GiGearHammer className={`ownership-icon`} />
                </Link>
            ),
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 80,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-36'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" checked={params.data.isactive}
                                onChange={() => handleStatus(params.data.id, params.data.isactive)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        
    ];

    const onGridReady = useCallback(async(params) => {
        loadData()
    })

    useEffect(() => {
        if (addStatus === true) {
            loadData()
        }
    }, [addStatus])

    const handleClick = () => setOpen(true);

    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex">
                            <Breadcrumbs mainTitle="Holding Companies" parent="Organizations" title="List" />
                        </div>
                        <Card className=" newtable">
                            <CardBody className='data-tables responsive-view ptop-0'>
                            <div className='exchange-table'>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={orgList}
                                            agRef={orgRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddSubOrganization value={open} setOpenModal={setOpen} setAdd={setAddStatus} />}
                </Row>
            </Container>
        </Fragment>
    );
};

export default SubOrganizationList;