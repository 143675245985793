import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button, Row, Col, FormGroup } from "reactstrap";
import moment from "moment";
import {
    categoryData, employeeCategoryData, employeeComplaints, parentalRetentionCategory,
    rehabilitaion, retirementBenefitsData, safetyIncident, yearlyDevelopCategoryData
} from "../../Settings/settingshelper";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";
import _ from 'lodash';
import { Trash2 } from "react-feather";

const gridOptions = {
    groupHeaderHeight: 35, // Set the height of the group header row
    headerHeight: 70,
}


const WellbeingTables = ({ rowData, columnData, context }) => {
    const [tableRowPinned, setRowPinned] = useState({
        category: "Total",
        totalEmployees: rowData?.reduce((sum, row) => sum + row?.totalEmployees, 0) || 0,
        healthInsuranceNumber: rowData?.reduce((sum, row) => sum + row?.healthInsuranceNumber, 0) || 0,
        healthInsurancePercentage: rowData?.reduce((sum, row) => sum + row?.healthInsurancePercentage, 0) || 0,
        accidentInsuranceNumber: rowData?.reduce((sum, row) => sum + row?.accidentInsuranceNumber, 0) || 0,
        accidentInsurancePercentage: rowData?.reduce((sum, row) => sum + row?.accidentInsurancePercentage, 0) || 0,
        maternityBenefitsNumber: rowData?.reduce((sum, row) => sum + row?.maternityBenefitsNumber, 0) || 0,
        maternityBenefitsPercentage: rowData?.reduce((sum, row) => sum + row?.maternityBenefitsPercentage, 0) || 0,
        paternityBenefitsNumber: rowData?.reduce((sum, row) => sum + row?.paternityBenefitsNumber, 0) || 0,
        paternityBenefitsPercentage: rowData?.reduce((sum, row) => sum + row?.paternityBenefitsPercentage, 0) || 0,
        dayCareFacilitiesNumber: rowData?.reduce((sum, row) => sum + row?.dayCareFacilitiesNumber, 0) || 0,
        dayCareFacilitiesPercentage: rowData?.reduce((sum, row) => sum + row?.dayCareFacilitiesPercentage, 0) || 0,
        editable: false
    })

    const handleEmployeeTotals = (data) => {
        return {
            totalEmployees: data?.reduce((sum, row) => {
                const value = Number(row?.totalEmployees);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            healthInsuranceNumber: data?.reduce((sum, row) => {
                const value = Number(row?.healthInsuranceNumber);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            healthInsurancePercentage: data?.reduce((sum, row) => {
                const value = Number(row?.healthInsurancePercentage);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            accidentInsuranceNumber: data?.reduce((sum, row) => {
                const value = Number(row?.accidentInsuranceNumber);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            accidentInsurancePercentage: data?.reduce((sum, row) => {
                const value = Number(row?.accidentInsurancePercentage);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            maternityBenefitsNumber: data?.reduce((sum, row) => {
                const value = Number(row?.maternityBenefitsNumber);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            maternityBenefitsPercentage: data?.reduce((sum, row) => {
                const value = Number(row?.maternityBenefitsPercentage);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            paternityBenefitsNumber: data?.reduce((sum, row) => {
                const value = Number(row?.paternityBenefitsNumber);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            paternityBenefitsPercentage: data?.reduce((sum, row) => {
                const value = Number(row?.paternityBenefitsPercentage);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            dayCareFacilitiesNumber: data?.reduce((sum, row) => {
                const value = Number(row?.dayCareFacilitiesNumber);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            dayCareFacilitiesPercentage: data?.reduce((sum, row) => {
                const value = Number(row?.dayCareFacilitiesPercentage);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
        }
    }

    useEffect(() => {
        setRowPinned({
            category: 'Total',
            ...handleEmployeeTotals(rowData),
            editable: false
        })

    }, [rowData])

    const onCellValueChanged = () => {
        const tempObj = handleEmployeeTotals(rowData);
        setRowPinned({
            category: 'Total',
            ...tempObj,
            editable: false
        })

    }

    return (
        <Fragment>
            <div className="employee">
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        context={context}
                        tableData={rowData}
                        columnData={columnData}
                        gridOptions={gridOptions}
                        pinnedBottomRowData={tableRowPinned}
                        onCellValueChanged={(params) => onCellValueChanged()}
                    />
                </div>
            </div>
        </Fragment>
    )
}

const TrainingComponent = ({ rowData, columnData, context }) => {
    const [tableRowPinned, setRowPinned] = useState({
        category: 'Total',
        totalCurrentFY: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.totalCurrentFY, 0) || 0,
        healthSafetyMeasuresCurrentFYNo: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.healthSafetyMeasuresCurrentFYNo, 0) || 0,
        healthSafetyMeasuresCurrentFYNoPercent: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.healthSafetyMeasuresCurrentFYNoPercent, 0) || 0,
        skillUpgradationCurrentFYNo: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.skillUpgradationCurrentFYNo, 0) || 0,
        skillUpgradationCurrentFYPercent: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.skillUpgradationCurrentFYPercent, 0) || 0,
        totalPreviousFY: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.totalPreviousFY, 0) || 0,
        healthSafetyMeasuresPreviousFYNo: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.healthSafetyMeasuresPreviousFYNo, 0) || 0,
        healthSafetyMeasuresPreviousFYNoPercent: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.healthSafetyMeasuresPreviousFYNoPercent, 0) || 0,
        skillUpgradationPreviousFYNo: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.skillUpgradationPreviousFYNo, 0) || 0,
        skillUpgradationPreviousFYPercent: rowData?.filter((ele, index) => index + 1 !== rowData?.length)
            ?.reduce((sum, row) => sum + row?.skillUpgradationPreviousFYPercent, 0) || 0,
        editable: false
    })

    const handleTotal = (data) => {
        return {
            totalCurrentFY: data?.reduce((sum, row) => {
                const value = Number(row?.totalCurrentFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            healthSafetyMeasuresCurrentFYNo: data?.reduce((sum, row) => {
                const value = Number(row?.healthSafetyMeasuresCurrentFYNo);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            healthSafetyMeasuresCurrentFYNoPercent: data?.reduce((sum, row) => {
                const value = Number(row?.healthSafetyMeasuresCurrentFYNoPercent);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            skillUpgradationCurrentFYNo: data?.reduce((sum, row) => {
                const value = Number(row?.skillUpgradationCurrentFYNo);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            skillUpgradationCurrentFYPercent: data?.reduce((sum, row) => {
                const value = Number(row?.skillUpgradationCurrentFYPercent);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            totalPreviousFY: data?.reduce((sum, row) => {
                const value = Number(row?.totalPreviousFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            healthSafetyMeasuresPreviousFYNo: data?.reduce((sum, row) => {
                const value = Number(row?.healthSafetyMeasuresPreviousFYNo);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            healthSafetyMeasuresPreviousFYNoPercent: data?.reduce((sum, row) => {
                const value = Number(row?.healthSafetyMeasuresPreviousFYNoPercent);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            skillUpgradationPreviousFYNo: data?.reduce((sum, row) => {
                const value = Number(row?.skillUpgradationPreviousFYNo);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            skillUpgradationPreviousFYPercent: data?.reduce((sum, row) => {
                const value = Number(row?.skillUpgradationPreviousFYPercent);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0
        }
    }

    const onCellValueChanged = () => {
        const tempObj = handleTotal(rowData);
        setRowPinned({
            category: 'Total',
            ...tempObj,
            editable: false
        })
    }

    useEffect(() => {
        setRowPinned({
            category: 'Total',
            ...handleTotal(rowData),
            editable: false
        })
    }, [rowData])



    return (
        <Fragment>
            <div className="ag-3layer-header">
                <div className="ag-group-header">
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                context={context}
                                tableData={rowData}
                                columnData={columnData}
                                gridOptions={gridOptions}
                                pinnedBottomRowData={tableRowPinned}
                                onCellValueChanged={() => onCellValueChanged()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const CareerDevelopmentComponent = ({ rowData, columnData, context }) => {
    const [tableRowPinned, setRowPinned] = useState({
        category: 'Total',
        totalCurrentFY: rowData?.reduce((sum, row) => sum + row?.totalCurrentFY, 0) || 0,
        numberCurrentFY: rowData?.reduce((sum, row) => sum + row?.numberCurrentFY, 0) || 0,
        percentageCurrentFY: rowData?.reduce((sum, row) => sum + row?.percentageCurrentFY, 0) || 0,
        totalPreviousFY: rowData?.reduce((sum, row) => sum + row?.totalPreviousFY, 0) || 0,
        numberPreviousFY: rowData?.reduce((sum, row) => sum + row?.numberPreviousFY, 0) || 0,
        percentagePreviousFY: rowData?.reduce((sum, row) => sum + row?.percentagePreviousFY, 0) || 0,
        editable: false
    })

    const handleCareerDevelopmentTotal = (data) => {
        return {
            totalCurrentFY: data?.reduce((sum, row) => {
                const value = Number(row?.totalCurrentFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            numberCurrentFY: data?.reduce((sum, row) => {
                const value = Number(row?.numberCurrentFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            percentageCurrentFY: data?.reduce((sum, row) => {
                const value = Number(row?.percentageCurrentFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            totalPreviousFY: data?.reduce((sum, row) => {
                const value = Number(row?.totalPreviousFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            numberPreviousFY: data?.reduce((sum, row) => {
                const value = Number(row?.numberPreviousFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            percentagePreviousFY: data?.reduce((sum, row) => {
                const value = Number(row?.percentagePreviousFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
        }
    }

    useEffect(() => {
        setRowPinned({
            category: 'Total',
            ...handleCareerDevelopmentTotal(rowData),
            editable: false
        })
    }, [rowData])

    const onCellValueChanged = () => {
        const tempObj = handleCareerDevelopmentTotal(rowData);
        setRowPinned({
            category: 'Total',
            ...tempObj,
            editable: false
        })
    }

    return (
        <Fragment>
            <div className="ngrbc-review">
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        context={context}
                        tableData={rowData}
                        columnData={columnData}
                        gridOptions={gridOptions}
                        onCellValueChanged={onCellValueChanged}
                        pinnedBottomRowData={tableRowPinned}
                    />
                </div>
            </div>
        </Fragment>
    )
}


const EmployeeWellbeing = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [retirementDetails, setRetirementDetails] = useState(retirementBenefitsData);
    const [employeeData, setEmployeeData] = useState(categoryData);
    const [exceptEmployeeData, setExceptEmployee] = useState(categoryData)
    const [workersData, setWorkerData] = useState(categoryData);
    const [exceptWorkerData, setExceptWorker] = useState(categoryData)
    const [currentYrStatus, setCurrentYearStatus] = useState();
    const [previousYrStatus, setPreviousYearStatus] = useState();
    const [diffAbledAct, setDiffAbledAct] = useState()
    const [equalityAct, setEqualityAct] = useState();
    const [returnedData, setReturnedData] = useState(parentalRetentionCategory);
    const [isPermentWorkersMechanism, setIsPermentWorkers] = useState();
    const [wokerMechanism, setWorkerMechanism] = useState();
    const [isOtherWorkersMechanism, setIsOtherWorkers] = useState();
    const [otherWokerMechanism, setOtherWorkerMechanism] = useState();
    const [isPermentEmployeeMechanism, setIsPermentEmployee] = useState();
    const [employeeMechanism, setEmployeeMechanism] = useState();
    const [isOtherEmployeeMechanism, setIsOtherEmployee] = useState();
    const [otherEmployeeMechanism, setOtheEmployeeMechanism] = useState();
    const [membershipData, setMembershipData] = useState(employeeCategoryData)
    const [employeeSkillUpgradation, setEmployeeSkillUpgradation] = useState(categoryData);
    const [WorkersSkillUpgradation, setWorkersSkillUpgradation] = useState(categoryData);
    const [employeeCareerData, setEmployeeCareer] = useState(yearlyDevelopCategoryData);
    const [workerCareerData, setWorkerCareer] = useState(yearlyDevelopCategoryData)
    const [isHSManagement, setIsHSManagement] = useState();
    const [coverageSystem, setCoverageSystem] = useState();
    const [hazardProcess, setHazardProcess] = useState();
    const [isWorkProcess, setIsWorkProcess] = useState();
    const [isMedicalServices, setMedicalServices] = useState()
    const [safetyIncidentData, setSafetyIncidentData] = useState(safetyIncident)
    const [safetyMeasures, setSafetyMeasures] = useState();
    const [employeeComplaintsData, setEmployeeComplaintsData] = useState(employeeComplaints)
    const [healthAssessment, setHealthAssessment] = useState();
    const [workingAssessment, setWorkingAssessment] = useState();
    const [assessmentDetails, setAssessmentDetails] = useState()
    const [isApplyInsuranceEmployee, setApplyInsuranceEmployee] = useState()
    const [isApplyInsuranceWorker, setApplyInsuranceWorker] = useState()
    const [statutoryMeasurements, setStatutoryMeasurements] = useState();
    const [rehabilitationData, setRehabilitationData] = useState(rehabilitaion);
    const [retirementPlan, setRetirementPlan] = useState()
    const [healthAssessmentPercentage, setHealthAssessmentPercentage] = useState();
    const [workingAssessmentPercentage, setWorkingAssessmentPercentage] = useState()
    const [riskDetails, setRiskDetails] = useState();
    const [valueAssessmentData, setValueAssessmentData] = useState();
    const [employeeExpenditureData, setEmployeeExpenditure] = useState();
    const [grievanceMechanismData, setGreivanceMechanismData] = useState()
    const [annualAssessmentData, setAnnualAssessmentData] = useState()

    const getGreivanceMechanismData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/grievanceMechanismData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setGreivanceMechanismData(res.json)
                setIsPermentWorkers(res.json[0]?.hasMechanism);
                setWorkerMechanism(res.json[0]?.mechanismDetails);
                setIsOtherWorkers(res.json[1]?.hasMechanism);
                setOtherWorkerMechanism(res.json[1]?.mechanismDetails);
                setIsPermentEmployee(res.json[2]?.hasMechanism);
                setEmployeeMechanism(res.json[2]?.mechanismDetails);
                setIsOtherEmployee(res.json[3]?.hasMechanism);
                setOtheEmployeeMechanism(res.json[3]?.mechanismDetails);
            }, (err) => console.log(err))
    }

    const getAnnualAssessmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/annualAssessmentData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setAnnualAssessmentData(res.json?.filter(ele => ele?.principleNumber === 3));
                setHealthAssessment(res.json[0]?.assessmentPercentage);
                setWorkingAssessment(res.json[1]?.assessmentPercentage);
            }, (err) => console.log(err))
    }

    const getEmployeeExpenditureData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeExpenditureData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setEmployeeExpenditure(res.json)
                setCurrentYearStatus(res.json[0]?.currentFinancialYearExpenses);
                setPreviousYearStatus(res.json[0]?.previousFinancialYearExpenses);
            }, (err) => console.log(err))
    }

    const getEmployeeCommitmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeCommitmentData/${financialYear}`)
            .then((res) => {
                const employeeArray = res.json?.filter(ele => ele?.type === 'Permanent Employees');
                const tempEmp = employeeArray?.filter(ele => categoryData?.find(item => item?.category === ele?.category))
                tempEmp?.length > 0 ? setEmployeeData(tempEmp) : setEmployeeData(categoryData);
                const permanentEmployeeArray = res.json?.filter(ele => ele?.type === 'Other than Permanent Employees');
                const tempOtherEmp = permanentEmployeeArray?.filter(ele => categoryData?.find(item => item?.category === ele?.category));
                tempOtherEmp?.length > 0 ? setExceptEmployee(tempOtherEmp) : setExceptEmployee(categoryData);
                const permanentWorkersArray = res.json?.filter(ele => ele?.type === 'Permanent Workers');
                const tempWorkerEmp = permanentWorkersArray?.filter(ele => categoryData?.find(item => item?.category === ele?.category));
                tempWorkerEmp?.length > 0 ? setWorkerData(tempWorkerEmp) : setWorkerData(categoryData);
                const otherPermanentWokerArray = res.json?.filter(ele => ele?.type === 'Other than Permanent Workers');
                const tempOtherPerWorker = otherPermanentWokerArray?.filter(ele => categoryData?.find(item => item?.category === ele?.category));
                tempOtherPerWorker?.length > 0 ? setExceptWorker(tempOtherPerWorker) : setExceptWorker(categoryData);
            }, (err) => {
                console.log(err)
                if (err.status === 404) {
                    setEmployeeData(categoryData);
                    setExceptEmployee(categoryData);
                    setWorkerData(categoryData)
                    setExceptWorker(categoryData)
                }
            })
    }

    const getValueAssessmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/valueChainPartnersAssessmentData/${financialYear}`)
            .then((res) => {
                setValueAssessmentData(res?.json?.filter(ele => ele?.principleNumber == 3))
                setHealthAssessmentPercentage(res?.json?.find(ele => ele?.particulars === 'Health and safety practices')?.assesmentPercentageByBusinessValue);
                setWorkingAssessmentPercentage(res?.json?.find(ele => ele?.particulars === 'Working Conditions')?.assesmentPercentageByBusinessValue);
            }, (err) => { console.log(err) })
    }

    const getRetirementBenefitsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/retirementBenefitsData/${financialYear}`)
            .then((res) => {
                const tempData = retirementBenefitsData?.map(ele => {
                    const temp = res?.json?.find(item => item?.benefitType === ele?.benefitType);
                    return temp ? temp : ele;
                })
                setRetirementDetails(tempData);
            }, (err) => {
                console.log(err)
            })
    }

    const getParentalRetentionData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/parentalRetentionData/${financialYear}`)
            .then((res) => {
                const tempData = parentalRetentionCategory?.map(ele => {
                    const temp = res?.json?.find(item => item?.gender === ele?.gender);
                    return temp ? temp : ele;
                })
                const aggregateTable = {
                    gender: 'Total',
                    employeeReturnToWorkRate: tempData?.filter((ele, index) => index + 1 !== tempData?.length)?.reduce((sum, row) => sum + row?.employeeReturnToWorkRate, 0) || 0,
                    employeeRetentionRate: tempData?.filter((ele, index) => index + 1 !== tempData?.length)?.reduce((sum, row) => sum + row?.employeeRetentionRate, 0) || 0,
                    workerReturnToWorkRate: tempData?.filter((ele, index) => index + 1 !== tempData?.length)?.reduce((sum, row) => sum + row?.workerReturnToWorkRate, 0) || 0,
                    workerRetentionRate: tempData?.filter((ele, index) => index + 1 !== tempData?.length)?.reduce((sum, row) => sum + row?.workerRetentionRate, 0) || 0
                }
                let tempArray = [...tempData]
                tempArray[tempData?.length - 1] = { ...aggregateTable }
                setReturnedData(tempArray);
            }, (err) => {
                console.log(err)
            })
    }

    const getUnionMembershipData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/unionMembershipData/${financialYear}`)
            .then((res) => {
                const tempData = employeeCategoryData?.map(ele => {
                    const temp = res?.json?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setMembershipData(tempData);
            }, (err) => {
                console.log(err)
            })
    }

    const getEmployeeWorkerTrainingData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeWorkerTrainingData/${financialYear}`)
            .then((res) => {
                console.log(res.json, 'empworker Training Data');
                const employeeTempData = res.json?.filter(ele => ele?.type === 'Employee');
                const tmpEmp = categoryData?.map(ele => {
                    const temp = employeeTempData?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setEmployeeSkillUpgradation(tmpEmp)
                const workersTempData = res.json?.filter(ele => ele?.type === 'Workers');
                const tmpWork = categoryData?.map(ele => {
                    const temp = workersTempData?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setWorkersSkillUpgradation(tmpWork)
            }, (err) => {
                console.log(err)
            })
    }

    const getYearlyDevelopmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/yearlyDevelopmentData/${financialYear}`)
            .then((res) => {
                const employeeArray = res.json?.filter((item) => item?.type === 'Employee')
                const workerDataArray = res.json?.filter((item) => item?.type === 'Workers')
                const tempData = yearlyDevelopCategoryData?.map(ele => {
                    const temp = employeeArray?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setEmployeeCareer(tempData)
                const tempWorkerData = yearlyDevelopCategoryData?.map(ele => {
                    const temp = workerDataArray?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                console.log(tempWorkerData, 'yearlyDevelopment')
                setWorkerCareer(tempWorkerData)
            }, (err) => {
                console.log(err)
            })
    }

    const getSafetyIncidentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/safetyIncidentData/${financialYear}`)
            .then((res) => {
                console.log(res.json, 'safety incident')
                let data = res.json?.map((item) => ({ ...item, incidentNumber: item?.category === 'Workers' ? '' : item?.incidentNumber }))
                let tempData = safetyIncident?.map(ele => {
                    const temp = data?.find(item => item?.category === ele?.category && item?.incidentNumber === ele?.incidentNumber);
                    return temp ? temp : ele;
                })
                setSafetyIncidentData(tempData)
            }, (err) => {
                console.log(err)
            })
    }

    const getEmployeeComplaintsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeComplaintsData/${financialYear}`)
            .then((res) => {
                const tempData = employeeComplaints?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setEmployeeComplaintsData(tempData);
            }, (err) => {
                console.log(err)
            })
    }

    const getEmployeeRehabilitationData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeRehabilitationData/${financialYear}`)
            .then((res) => {
                const tempData = rehabilitaion?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setRehabilitationData(tempData);
            }, (err) => {
                console.log(err)
            })
    }

    const getAttributesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                const data = res.json?.filter(ele => ele.groupName === 'employeeWellbeingPromotion');
                setDiffAbledAct(data?.find(ele => ele.key === 'Q14')?.value);
                setEqualityAct(data?.find(ele => ele.key === 'Q15')?.value);
                setIsPermentWorkers(data?.find(ele => ele.key === 'Q16')?.value);
                setWorkerMechanism(data?.find(ele => ele.key === 'Q17')?.value);
                setIsOtherWorkers(data?.find(ele => ele.key === 'Q18')?.value);
                setOtherWorkerMechanism(data?.find(ele => ele.key === 'Q19')?.value);
                setIsPermentEmployee(data?.find(ele => ele.key === 'Q20')?.value);
                setEmployeeMechanism(data?.find(ele => ele.key === 'Q21')?.value);
                setIsOtherEmployee(data?.find(ele => ele.key === 'Q22')?.value);
                setOtheEmployeeMechanism(data?.find(ele => ele.key === 'Q23')?.value);
                setIsHSManagement(data?.find(ele => ele.key === 'Q24')?.value);
                setCoverageSystem(data?.find(ele => ele.key === 'Q25')?.value);
                setHazardProcess(data?.find(ele => ele.key === 'Q26')?.value);
                setIsWorkProcess(data?.find(ele => ele.key === 'Q27')?.value);
                setMedicalServices(data?.find(ele => ele.key === 'Q28')?.value);
                setSafetyMeasures(data?.find(ele => ele.key === 'Q29')?.value);
                setHealthAssessment(data?.find(ele => ele.key === 'Q30')?.value);
                setWorkingAssessment(data?.find(ele => ele.key === 'Q31')?.value);
                setAssessmentDetails(data?.find(ele => ele.key === 'Q32')?.value);
                setApplyInsuranceEmployee(data?.find(ele => ele.key === 'Q33')?.value);
                setApplyInsuranceWorker(data?.find(ele => ele.key === 'Q34')?.value);
                setStatutoryMeasurements(data?.find(ele => ele.key === 'Q35')?.value);
                setRetirementPlan(data?.find(ele => ele.key === 'Q36')?.value);
                setRiskDetails(data?.find(ele => ele.key === 'Q37')?.value);
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (financialYear) {
            getEmployeeCommitmentData()
            getRetirementBenefitsData()
            getParentalRetentionData()
            getUnionMembershipData()
            getEmployeeWorkerTrainingData()
            getYearlyDevelopmentData()
            getSafetyIncidentData()
            getEmployeeComplaintsData()
            getEmployeeRehabilitationData()
            getAttributesData()
            getValueAssessmentData()
            getEmployeeExpenditureData()
            getGreivanceMechanismData()
            getAnnualAssessmentData()
        }
    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
        if (type === 1 && params.context === 'employees') {
            updatedRowData = employeeData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setEmployeeData(updatedRowData)
        } else if (type === 1 && params.context === 'otherEmployees') {
            updatedRowData = exceptEmployeeData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setExceptEmployee(updatedRowData);
        } else if (type === 1 && params.context === 'workers') {
            updatedRowData = workersData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setWorkerData(updatedRowData)
        } else if (type === 1 && params.context === 'otherWorkers') {
            updatedRowData = exceptWorkerData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setExceptWorker(updatedRowData)
        } else if (type === 2) {
            updatedRowData = retirementDetails?.map((row, index) => {
                return (index == params.node.rowIndex) ? { benefitType: row?.benefitType } : { ...row }
            });
            setRetirementDetails(updatedRowData);
        } else if (type === 3) {
            updatedRowData = returnedData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { gender: row?.gender } : { ...row }
            });
            setReturnedData(updatedRowData);
        } else if (type === 4) {
            updatedRowData = membershipData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setMembershipData(updatedRowData);
        } else if (type === 5 && params.context === 'employees') {
            updatedRowData = employeeSkillUpgradation?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setEmployeeSkillUpgradation(updatedRowData);
        } else if (type === 5 && params.context === 'workers') {
            updatedRowData = WorkersSkillUpgradation?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setWorkersSkillUpgradation(updatedRowData);
        } else if (type === 6 && params.context === 'employees') {
            updatedRowData = employeeCareerData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setEmployeeCareer(updatedRowData);
        } else if (type === 6 && params.context === 'workers') {
            updatedRowData = workerCareerData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setWorkerCareer(updatedRowData);
        } else if (type === 7) {
            updatedRowData = safetyIncidentData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category, incidentNumber: row?.incidentNumber } : { ...row }
            });
            setSafetyIncidentData(updatedRowData)
        } else if (type === 8) {
            updatedRowData = employeeComplaintsData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars, incidentNumber: row?.incidentNumber } : { ...row }
            });
            setEmployeeComplaintsData(updatedRowData)
        } else if(type === 9) {
            updatedRowData = rehabilitationData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { category: row?.category } : { ...row }
            });
            setRehabilitationData(updatedRowData)
        }
    }

    const retirementBenefitsColumn = [
        {
            headerName: 'Benefits',
            field: 'benefitType',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: '% of Employee Covered',
                    field: 'currentFYEmployeesCoveredPercentage',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.currentFYEmployeesCoveredPercentage ?
                            thousandSeparator(params?.data?.currentFYEmployeesCoveredPercentage.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: '% of Workers covered',
                    field: 'currentFYWorkersCoveredPercentage',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.currentFYWorkersCoveredPercentage ?
                            thousandSeparator(params?.data?.currentFYWorkersCoveredPercentage.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Deducted & deposited',
                    field: 'currentFYDeductedDeposited',
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['Yes', 'No', 'Not Applicable']
                    },
                    filter: false,
                    editable: true,
                    flex: 1.5,
                    sortable: false,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: '% of Employee Covered',
                    field: 'previousFYEmployeesCoveredPercentage',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.previousFYEmployeesCoveredPercentage ?
                            thousandSeparator(params?.data?.previousFYEmployeesCoveredPercentage.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: '% of Workers covered',
                    field: 'previousFYWorkersCoveredPercentage',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.previousFYWorkersCoveredPercentage ?
                            thousandSeparator(params?.data?.previousFYWorkersCoveredPercentage.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Deducted & deposited',
                    field: 'previousFYDeductedDeposited',
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['Yes', 'No', 'Not Applicable']
                    },
                    filter: false,
                    editable: true,
                    flex: 1.5,
                    sortable: false,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('currentFYEmployeesCoveredPercentage') ||
        //             params.data.hasOwnProperty('currentFYWorkersCoveredPercentage') ||
        //             params.data.hasOwnProperty('currentFYDeductedDeposited') ||
        //             params.data.hasOwnProperty('previousFYEmployeesCoveredPercentage') ||
        //             params.data.hasOwnProperty('previousFYWorkersCoveredPercentage') ||
        //             params.data.hasOwnProperty('previousFYDeductedDeposited'))
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 2)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const wellbeingColumns = [
        {
            headerName: 'Category',
            field: 'category',
            editable: false,
            filter: false,
            flex: 1.25,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Total (A)',
            field: 'totalEmployees',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
            },
            flex: 0.75,
            editable: (params) => {
                return params.node.rowPinned !== 'bottom'
            },
            cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
        },
        {
            headerName: 'Health insurance',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'No. (B)',
                    field: 'healthInsuranceNumber',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalEmployees > params.data.healthInsuranceNumber) {
                            return params.data.healthInsuranceNumber;
                        } else {
                            delete params.data.healthInsuranceNumber;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',

                },
                {
                    headerName: '% (B/A)',
                    field: 'healthInsurancePercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.healthInsuranceNumber && params.data?.totalEmployees) {
                            params.data.healthInsurancePercentage = (Number(params.data?.healthInsuranceNumber) / Number(params.data?.totalEmployees)) * 100;
                            return params.data?.healthInsurancePercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.healthInsurancePercentage?.toFixed(2)}</div>
                    },
                    editable: false,
                    cellClass: 'disable-cell'
                },
            ]
        },
        {
            headerName: 'Accident insurance',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'No. (C)',
                    field: 'accidentInsuranceNumber',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalEmployees > params.data.accidentInsuranceNumber) {
                            return params.data.accidentInsuranceNumber;
                        } else {
                            delete params.data.accidentInsuranceNumber;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (C/A)',
                    field: 'accidentInsurancePercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.accidentInsuranceNumber && params.data?.totalEmployees) {
                            params.data.accidentInsurancePercentage = (Number(params.data?.accidentInsuranceNumber) / Number(params.data?.totalEmployees)) * 100;
                            return params.data?.accidentInsurancePercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.accidentInsurancePercentage?.toFixed(2)}</div>
                    },
                    editable: false,
                    cellClass: 'disable-cell'
                },
            ]
        },
        {
            headerName: 'Maternity benefits ',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'No. (D)',
                    field: 'maternityBenefitsNumber',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalEmployees > params.data.maternityBenefitsNumber) {
                            return params.data.maternityBenefitsNumber;
                        } else {
                            delete params.data.maternityBenefitsNumber;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (D/A)',
                    field: 'maternityBenefitsPercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.maternityBenefitsNumber && params.data?.totalEmployees) {
                            params.data.maternityBenefitsPercentage = (Number(params.data?.maternityBenefitsNumber) / Number(params.data?.totalEmployees)) * 100;
                            return params.data?.maternityBenefitsPercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.maternityBenefitsPercentage?.toFixed(2)}</div>
                    },
                    editable: false,
                    cellClass: 'disable-cell'
                },
            ]
        },
        {
            headerName: 'Paternity benefits ',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'No. (E)',
                    field: 'paternityBenefitsNumber',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalEmployees > params.data.paternityBenefitsNumber) {
                            return params.data.paternityBenefitsNumber;
                        } else {
                            delete params.data.paternityBenefitsNumber;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (E/A)',
                    field: 'paternityBenefitsPercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.paternityBenefitsNumber && params.data?.totalEmployees) {
                            params.data.paternityBenefitsPercentage = (Number(params.data?.paternityBenefitsNumber) / Number(params.data?.totalEmployees)) * 100;
                            return params.data?.paternityBenefitsPercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.paternityBenefitsPercentage?.toFixed(2)}</div>
                    },
                    editable: false,
                    cellClass: 'disable-cell'
                },
            ]
        },
        {
            headerName: 'Day Care facilities',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'No. (F)',
                    field: 'dayCareFacilitiesNumber',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalEmployees > params.data.dayCareFacilitiesNumber) {
                            return params.data.dayCareFacilitiesNumber;
                        } else {
                            delete params.data.dayCareFacilitiesNumber;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (F/A)',
                    field: 'dayCareFacilitiesPercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.dayCareFacilitiesNumber && params.data?.totalEmployees) {
                            params.data.dayCareFacilitiesPercentage = (Number(params.data?.dayCareFacilitiesNumber) / Number(params.data?.totalEmployees)) * 100;
                            return params.data?.dayCareFacilitiesPercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.dayCareFacilitiesPercentage?.toFixed(2)}</div>
                    },
                    editable: false,
                    cellClass: 'disable-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('totalEmployees') ||
        //             params.data.hasOwnProperty('healthInsuranceNumber') ||
        //             params.data.hasOwnProperty('accidentInsuranceNumber') ||
        //             params.data.hasOwnProperty('maternityBenefitsNumber') ||
        //             params.data.hasOwnProperty('paternityBenefitsNumber') ||
        //             params.data.hasOwnProperty('dayCareFacilitiesNumber')) &&
        //             params.node.rowPinned !== 'bottom'
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)} />
        //                 </div>
        //             )
        //     }
        // }
    ];


    const returnColumns = [
        {
            headerName: 'Gender',
            field: 'gender',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `Permanent Employees`,
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Return to Work rate (%)',
                    field: 'employeeReturnToWorkRate',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.employeeReturnToWorkRate ?
                            thousandSeparator(params?.data?.employeeReturnToWorkRate.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Retention rate (%)',
                    field: 'employeeRetentionRate',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.employeeRetentionRate ?
                            thousandSeparator(params?.data?.employeeRetentionRate.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: `Permanent Workers`,
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Return to Work rate (%)',
                    field: 'workerReturnToWorkRate',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.workerReturnToWorkRate ?
                            thousandSeparator(params?.data?.workerReturnToWorkRate.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Retention rate (%)',
                    field: 'workerRetentionRate',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.workerRetentionRate ?
                            thousandSeparator(params?.data?.workerRetentionRate.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('employeeReturnToWorkRate') ||
        //             params.data.hasOwnProperty('employeeRetentionRate') ||
        //             params.data.hasOwnProperty('workerReturnToWorkRate') ||
        //             params.data.hasOwnProperty('workerRetentionRate')) &&
        //             params.node.rowPinned !== 'bottom'
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 3)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const membershipColumn = [
        {
            headerName: 'Category',
            field: 'category',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Total Emp/Wkrs (A)',
                    field: 'totalCurrentEmployeesWorkers',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Emp/Wkrs in Associations (B)',
                    field: 'currentMembershipCount',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: '% (B/A)',
                    field: 'currentMembershipPercentage',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalCurrentEmployeesWorkers && params.data.currentMembershipCount) {
                            params.data.currentMembershipPercentage = (Number(params.data.currentMembershipCount) / Number(params.data.totalCurrentEmployeesWorkers)) * 100;
                            return params.data.currentMembershipPercentage
                        } else {
                            delete params.data.currentMembershipPercentage;
                        }
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.currentMembershipPercentage ?
                            thousandSeparator(params?.data?.currentMembershipPercentage.toFixed(2)) : ''}</div>;
                    },
                    flex: 1,
                    cellClass: 'disable-cell',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Total Emp/Wkrs (A)',
                    field: 'totalPreviousEmployeesWorkers',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Emp/Wkrs in Associations (B)',
                    field: 'previousMembershipCount',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: '% (B/A)',
                    field: 'previousMembershipPercentage',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalPreviousEmployeesWorkers && params.data.previousMembershipCount) {
                            params.data.previousMembershipPercentage = (Number(params.data.previousMembershipCount) / Number(params.data.totalPreviousEmployeesWorkers)) * 100;
                            return params.data.previousMembershipPercentage
                        } else {
                            delete params.data.previousMembershipPercentage;
                        }
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.previousMembershipPercentage ?
                            thousandSeparator(params?.data?.previousMembershipPercentage.toFixed(2)) : ''}</div>;
                    },
                    flex: 1,
                    cellClass: 'disable-cell',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('totalCurrentEmployeesWorkers') ||
        //             params.data.hasOwnProperty('currentMembershipCount') ||
        //             params.data.hasOwnProperty('totalPreviousEmployeesWorkers') ||
        //             params.data.hasOwnProperty('previousMembershipCount'))
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 4)} />
        //                 </div>
        //             )
        //     }
        // }
    ]

    const trainingColumn = [
        {
            headerName: 'Category',
            field: 'category',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Total (A)',
                    field: 'totalCurrentFY',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: `Health & Safety Measures`,
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align f-w-500',
                    children: [
                        {
                            headerName: 'No. (B)',
                            field: 'healthSafetyMeasuresCurrentFYNo',
                            editable: (params) => {
                                return params.node.rowPinned !== 'bottom'
                            },
                            cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '% (B/A)',
                            field: 'healthSafetyMeasuresCurrentFYNoPercent',
                            editable: true,
                            cellClass: 'disable-cell',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            valueGetter: (params) => {
                                if (params.data.totalCurrentFY && params.data.healthSafetyMeasuresCurrentFYNo) {
                                    params.data.healthSafetyMeasuresCurrentFYNoPercent = (Number(params.data.healthSafetyMeasuresCurrentFYNo) / Number(params.data.totalCurrentFY)) * 100;
                                    return params.data.healthSafetyMeasuresCurrentFYNoPercent
                                } else {
                                    delete params.data.healthSafetyMeasuresCurrentFYNoPercent;
                                }
                            },
                            cellRenderer: (params) => {
                                return <div className="text-right w-100">{params?.data?.healthSafetyMeasuresCurrentFYNoPercent ?
                                    thousandSeparator(params?.data?.healthSafetyMeasuresCurrentFYNoPercent.toFixed(2)) : ''}</div>;
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                },
                {
                    headerName: `Skill Upgradation`,
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align f-w-500',
                    children: [
                        {
                            headerName: 'No. (C)',
                            field: 'skillUpgradationCurrentFYNo',
                            editable: (params) => {
                                return params.node.rowPinned !== 'bottom'
                            },
                            cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '% (C/A)',
                            field: 'skillUpgradationCurrentFYPercent',
                            editable: true,
                            cellClass: 'disable-cell',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            valueGetter: (params) => {
                                if (params.data.totalCurrentFY && params.data.skillUpgradationCurrentFYNo) {
                                    params.data.skillUpgradationCurrentFYPercent = (Number(params.data.skillUpgradationCurrentFYNo) / Number(params.data.totalCurrentFY)) * 100;
                                    return params.data.skillUpgradationCurrentFYPercent
                                } else {
                                    delete params.data.skillUpgradationCurrentFYPercent;
                                }
                            },
                            cellRenderer: (params) => {
                                return <div className="text-right w-100">{params?.data?.skillUpgradationCurrentFYPercent ?
                                    thousandSeparator(params?.data?.skillUpgradationCurrentFYPercent.toFixed(2)) : ''}</div>;
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                }
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Total (D)',
                    field: 'totalPreviousFY',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: `Health & Safety Measures`,
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align f-w-500',
                    children: [
                        {
                            headerName: 'No. (E)',
                            field: 'healthSafetyMeasuresPreviousFYNo',
                            editable: (params) => {
                                return params.node.rowPinned !== 'bottom'
                            },
                            cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '% (E/D)',
                            field: 'healthSafetyMeasuresPreviousFYNoPercent',
                            editable: true,
                            cellClass: 'disable-cell',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            valueGetter: (params) => {
                                if (params.data.totalPreviousFY && params.data.healthSafetyMeasuresPreviousFYNo) {
                                    params.data.healthSafetyMeasuresPreviousFYNoPercent = (Number(params.data.healthSafetyMeasuresPreviousFYNo) / Number(params.data.totalPreviousFY)) * 100;
                                    return params.data.healthSafetyMeasuresPreviousFYNoPercent
                                } else {
                                    delete params.data.healthSafetyMeasuresPreviousFYNoPercent;
                                }
                            },
                            cellRenderer: (params) => {
                                return <div className="text-right w-100">{params?.data?.healthSafetyMeasuresPreviousFYNoPercent ?
                                    thousandSeparator(params?.data?.healthSafetyMeasuresPreviousFYNoPercent.toFixed(2)) : ''}</div>;
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                },
                {
                    headerName: `Skill Upgradation`,
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align f-w-500',
                    children: [
                        {
                            headerName: 'No. (F)',
                            field: 'skillUpgradationPreviousFYNo',
                            editable: (params) => {
                                return params.node.rowPinned !== 'bottom'
                            },
                            cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '% (F/D)',
                            field: 'skillUpgradationPreviousFYPercent',
                            editable: true,
                            cellClass: 'disable-cell',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            valueGetter: (params) => {
                                if (params.data.totalPreviousFY && params.data.skillUpgradationPreviousFYNo) {
                                    params.data.skillUpgradationPreviousFYPercent = (Number(params.data.skillUpgradationPreviousFYNo) / Number(params.data.totalPreviousFY)) * 100;
                                    return params.data.skillUpgradationPreviousFYPercent
                                } else {
                                    delete params.data.skillUpgradationPreviousFYPercent;
                                }
                            },
                            cellRenderer: (params) => {
                                return <div className="text-right w-100">{params?.data?.skillUpgradationPreviousFYPercent ?
                                    thousandSeparator(params?.data?.skillUpgradationPreviousFYPercent.toFixed(2)) : ''}</div>;
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                }
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('totalCurrentFY') ||
        //             params.data.hasOwnProperty('healthSafetyMeasuresCurrentFYNo') ||
        //             params.data.hasOwnProperty('skillUpgradationCurrentFYNo') ||
        //             params.data.hasOwnProperty('totalPreviousFY') ||
        //             params.data.hasOwnProperty('healthSafetyMeasuresPreviousFYNo') ||
        //             params.data.hasOwnProperty('skillUpgradationPreviousFYNo')) &&
        //             params.node.rowPinned !== 'bottom'
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 5)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const careerDevelopmentColumns = [
        {
            headerName: '',
            field: 'category',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Total (A)',
                    field: 'totalCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.totalCurrentFY ?
                            thousandSeparator(params?.data?.totalCurrentFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true
                },
                {
                    headerName: 'No. (B)',
                    field: 'numberCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.numberCurrentFY ?
                            thousandSeparator(params?.data?.numberCurrentFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true
                },
                {
                    headerName: '% (B/A)',
                    field: 'percentageCurrentFY',
                    filter: false,
                    editable: true,
                    cellClass: 'disable-cell',
                    flex: 1.5,
                    sortable: false,
                    valueGetter: (params) => {
                        if (params.data.totalCurrentFY && params.data.numberCurrentFY) {
                            params.data.percentageCurrentFY = (Number(params.data.numberCurrentFY) / Number(params.data.totalCurrentFY)) * 100;
                            return params.data.percentageCurrentFY
                        } else {
                            delete params.data.percentageCurrentFY;
                        }
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.percentageCurrentFY ?
                            thousandSeparator(params?.data?.percentageCurrentFY.toFixed(2)) : ''}</div>;
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Total (C)',
                    field: 'totalPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.totalPreviousFY ?
                            thousandSeparator(params?.data?.totalPreviousFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true
                },
                {
                    headerName: 'No. (D)',
                    field: 'numberPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.numberPreviousFY ?
                            thousandSeparator(params?.data?.numberPreviousFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    sortable: true
                },
                {
                    headerName: '% (D/C)',
                    field: 'percentagePreviousFY',
                    valueGetter: (params) => {
                        if (params.data.totalPreviousFY && params.data.numberPreviousFY) {
                            params.data.percentagePreviousFY = (Number(params.data.numberPreviousFY) / Number(params.data.totalPreviousFY)) * 100;
                            return params.data.percentagePreviousFY
                        } else {
                            delete params.data.percentagePreviousFY;
                        }
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.percentagePreviousFY ?
                            thousandSeparator(params?.data?.percentagePreviousFY.toFixed(2)) : ''}</div>;
                    },
                    filter: false,
                    editable: true,
                    cellClass: 'disable-cell',
                    flex: 1.5,
                    sortable: false,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('totalCurrentFY') ||
        //             params.data.hasOwnProperty('numberCurrentFY') ||
        //             params.data.hasOwnProperty('totalPreviousFY') ||
        //             params.data.hasOwnProperty('numberPreviousFY')) &&
        //             params.node.rowPinned !== 'bottom'
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 6)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const safetyIncidentColumns = [
        {
            headerName: 'Safety Incident/Number ',
            field: 'incidentNumber',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            sortable: false,
            rowSpan: (params) => {
                return 2;
            },
            cellClass: 'cell-wrap',
            cellClassRules: {
                "rowSpan-style": "value !== ''"
            }
        },
        {
            headerName: 'Category',
            field: 'category',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            field: 'currentFY',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentFY ?
                    thousandSeparator(params?.data?.currentFY) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            field: 'previousFY',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousFY ?
                    thousandSeparator(params?.data?.previousFY) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('currentFY') ||
        //             params.data.hasOwnProperty('previousFY'))
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 7)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const complaintsColumn = [
        {
            headerName: 'Benefits',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'No of Filed',
                    field: 'filledCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.filledCurrentFY ?
                            thousandSeparator(params?.data?.filledCurrentFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Pending',
                    field: 'pendingResolutionCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.pendingResolutionCurrentFY ?
                            thousandSeparator(params?.data?.pendingResolutionCurrentFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksCurrentFY',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    filter: false,
                    flex: 2,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'No of Filed',
                    field: 'filledPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.filledPreviousFY ?
                            thousandSeparator(params?.data?.filledPreviousFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Pending',
                    field: 'pendingResolutionPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.pendingResolutionPreviousFY ?
                            thousandSeparator(params?.data?.pendingResolutionPreviousFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksPreviousFY',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    filter: false,
                    flex: 2,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('filledCurrentFY') ||
        //             params.data.hasOwnProperty('pendingResolutionCurrentFY') ||
        //             params.data.hasOwnProperty('remarksCurrentFY') ||
        //             params.data.hasOwnProperty('filledPreviousFY') ||
        //             params.data.hasOwnProperty('pendingResolutionPreviousFY') ||
        //             params.data.hasOwnProperty('remarksPreviousFY'))
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 8)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const selfEmployedColumns = [
        {
            headerName: '',
            field: 'category',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Total No of Affected',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: financialYear ? `${financialYear}` : 'FY _____',
                    field: 'totalAffectedCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.totalAffectedCurrentFY ?
                            thousandSeparator(params?.data?.totalAffectedCurrentFY) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: financialYear ? `${previousYear}` : 'FY _____',
                    field: 'totalAffectedPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.totalAffectedPreviousFY ?
                            thousandSeparator(params?.data?.totalAffectedPreviousFY) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
            ]
        },
        {
            headerName: 'Rehabilitated/Placed Employees & Families',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: financialYear ? `${financialYear}` : 'FY _____',
                    field: 'rehabilitatedCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.rehabilitatedCurrentFY ?
                            thousandSeparator(params?.data?.rehabilitatedCurrentFY) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: financialYear ? `${previousYear}` : 'FY _____',
                    field: 'rehabilitatedPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.rehabilitatedPreviousFY ?
                            thousandSeparator(params?.data?.rehabilitatedPreviousFY) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if ((params.data.hasOwnProperty('totalAffectedCurrentFY') ||
        //             params.data.hasOwnProperty('totalAffectedPreviousFY') ||
        //             params.data.hasOwnProperty('rehabilitatedCurrentFY') ||
        //             params.data.hasOwnProperty('rehabilitatedPreviousFY'))
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 9)} />
        //                 </div>
        //             )
        //     }
        // }
    ]

    const parentalRetensionCellChange = (params) => {
        const aggregateTable = {
            gender: 'Total',
            employeeReturnToWorkRate: returnedData?.filter((ele, index) => index + 1 !== returnedData?.length)?.reduce((sum, row) => sum + row?.employeeReturnToWorkRate, 0) || 0,
            employeeRetentionRate: returnedData?.filter((ele, index) => index + 1 !== returnedData?.length)?.reduce((sum, row) => sum + row?.employeeRetentionRate, 0) || 0,
            workerReturnToWorkRate: returnedData?.filter((ele, index) => index + 1 !== returnedData?.length)?.reduce((sum, row) => sum + row?.workerReturnToWorkRate, 0) || 0,
            workerRetentionRate: returnedData?.filter((ele, index) => index + 1 !== returnedData?.length)?.reduce((sum, row) => sum + row?.workerRetentionRate, 0) || 0
        }
        let tempData = [...returnedData]
        tempData[returnedData?.length - 1] = { ...aggregateTable }
        setReturnedData(tempData)
    }

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    }

    const getTotal = (data) => {
        const tot = data.reduce((acc, obj) => {
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'number') acc[key] = (acc[key] || 0) + obj[key];
            });
            return acc;
        }, {});
        return tot;
    }

    const onSubmit = async () => {
        let valueAssessment, employeeExpenditure, grievanceMechanism, annualAssessment;
        if (annualAssessmentData) {
            annualAssessment = [...annualAssessmentData];
            annualAssessment[0].assessmentPercentage = healthAssessment;
            annualAssessment[1].assessmentPercentage = workingAssessment
        } else {
            annualAssessment = [
                {
                    serialNo: 1,
                    principleNumber: 3,
                    assessmentPercentage: healthAssessment,
                    particulars: "Health and safety practices"
                },
                {
                    serialNo: 1,
                    principleNumber: 3,
                    assessmentPercentage: workingAssessment,
                    particulars: "Working Conditions"
                }
            ]
        }
        if (grievanceMechanism) {
            grievanceMechanism = [...grievanceMechanismData];
            grievanceMechanism[0].hasMechanism = isPermentWorkersMechanism;
            grievanceMechanism[0].mechanismDetails = wokerMechanism;
            grievanceMechanism[1].hasMechanism = isOtherWorkersMechanism;
            grievanceMechanism[1].mechanismDetails = otherWokerMechanism;
            grievanceMechanism[2].hasMechanism = isPermentEmployeeMechanism;
            grievanceMechanism[2].mechanismDetails = employeeMechanism;
            grievanceMechanism[3].hasMechanism = isOtherEmployeeMechanism;
            grievanceMechanism[3].mechanismDetails = otherEmployeeMechanism;
        } else {
            grievanceMechanism = [
                {
                    serialNo: 1,
                    particulars: "Permanent Workers",
                    hasMechanism: isPermentWorkersMechanism,
                    mechanismDetails: wokerMechanism,
                    financialYear: financialYear
                },
                {
                    serialNo: 2,
                    particulars: "Other than Permanent Workers",
                    hasMechanism: isOtherWorkersMechanism,
                    mechanismDetails: otherWokerMechanism,
                    financialYear: financialYear
                },
                {
                    serialNo: 3,
                    particulars: "Permanent Employees",
                    hasMechanism: isPermentEmployeeMechanism,
                    mechanismDetails: employeeMechanism,
                    financialYear: financialYear
                },
                {
                    serialNo: 4,
                    particulars: "Other than Permanent Employees",
                    hasMechanism: isOtherEmployeeMechanism,
                    mechanismDetails: otherEmployeeMechanism,
                    financialYear: financialYear
                },
            ]
        }
        if (employeeExpenditureData) {
            employeeExpenditure = [...employeeExpenditureData];
            employeeExpenditure[0].currentFinancialYearExpenses = currentYrStatus;
            employeeExpenditure[0].previousFinancialYearExpenses = previousYrStatus;
        } else {
            employeeExpenditure = {
                serialNo: 1,
                particulars: "employee",
                currentFinancialYearExpenses: currentYrStatus,
                previousFinancialYearExpenses: previousYrStatus
            }
        }
        if (valueAssessmentData?.length > 0) {
            valueAssessment = [...valueAssessmentData];
            valueAssessment[0].assesmentPercentageByBusinessValue = parseFloat(healthAssessmentPercentage);
            valueAssessment[1].assesmentPercentageByBusinessValue = parseFloat(workingAssessmentPercentage);
        } else {
            valueAssessment = [
                {
                    serialNo: 1,
                    particulars: 'Health and safety practices',
                    assesmentPercentageByBusinessValue: parseFloat(healthAssessmentPercentage),
                    principleNumber: 3
                },
                {
                    serialNo: 2,
                    particulars: 'Working Conditions',
                    assesmentPercentageByBusinessValue: parseFloat(workingAssessmentPercentage),
                    principleNumber: 3
                }
            ]
        }
        const bodyData = {
            financialYear: financialYear,
            employeeCommitmentData: [
                ...[...employeeData, { ...getTotal(employeeData), category: 'Total' }]
                    ?.map((item, index) => ({ ...item, userType: 'Employee', type: "Permanent Employees", serialNo: index + 1 })),
                ...[...exceptEmployeeData, { ...getTotal(exceptEmployeeData), category: 'Total' }]
                    ?.map((item, index) => ({ ...item, userType: 'Employee', type: "Other than Permanent Employees", serialNo: 5 + index })),
                ...[...workersData, { ...getTotal(workersData), category: 'Total' }]
                    ?.map((item, index) => ({ ...item, userType: 'Worker', type: "Permanent Workers", serialNo: index + 1 })),
                ...[...exceptWorkerData, { ...getTotal(exceptWorkerData), category: 'Total' }]
                    ?.map((item, index) => ({ ...item, userType: 'Worker', type: "Other than Permanent Workers", serialNo: 5 + index }))
            ],
            retirementBenefitsData: retirementDetails?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            parentalRetentionData: returnedData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            unionMembershipData: membershipData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            employeeWorkerTrainingData: [
                ...[...employeeSkillUpgradation, { ...getTotal(employeeSkillUpgradation), category: 'Total' }]?.map((item, index) =>
                    ({ ...item, userType: 'Employee', serialNo: index + 1 })),
                ...[...WorkersSkillUpgradation, { ...getTotal(WorkersSkillUpgradation), category: 'Total' }]?.map((item, index) =>
                    ({ ...item, userType: 'Workers', serialNo: 5 + index }))
            ],
            yearlyDevelopmentData: [
                ...[...employeeCareerData, { ...getTotal(employeeCareerData), category: 'Total' }]?.map((item, index) => ({ ...item, userType: 'Employee', serialNo: index + 1 })),
                ...[...workerCareerData, { ...getTotal(workerCareerData), category: 'Total' }]?.map((item, index) => ({ ...item, userType: 'Workers', serialNo: 5 + index }))
            ]?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            safetyIncidentData: safetyIncident?.map((ele, index) =>
                ({ ...ele, incidentNumber: ele?.incidentNumber ? ele?.incidentNumber : safetyIncident[index - 1]?.incidentNumber, serialNo: index + 1 })),
            employeeComplaintsData: employeeComplaintsData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            employeeRehabilitationData: rehabilitationData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            valueChainPartnersAssessmentData: valueAssessment,
            grievanceMechanismData: grievanceMechanism,
            annualAssessmentData: annualAssessment,
            attributesKeyValueData: [
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q11',
                    value: diffAbledAct
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q12',
                    value: equalityAct
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q12',
                    value: isHSManagement
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q13',
                    value: coverageSystem
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q14',
                    value: hazardProcess
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q15',
                    value: isWorkProcess
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q16',
                    value: isMedicalServices
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q17',
                    value: safetyMeasures
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q18',
                    value: assessmentDetails
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q19',
                    value: isApplyInsuranceEmployee
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q20',
                    value: isApplyInsuranceWorker
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q21',
                    value: statutoryMeasurements
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q22',
                    value: retirementPlan
                },
                {
                    serialNo: 3,
                    groupName: 'employeeWellbeingPromotion',
                    key: 'Q23',
                    value: riskDetails
                }
            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(4);
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <h3>Employee Well-being Promotion</h3>
            <h4 className="mtop-1">Essential Indicators</h4>

            <div className="mtop-1 company-profile ag-group-header">
                <h5>1. a. Details of measures for the well-being of employees</h5>
                <p>Permanent Employees</p>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <WellbeingTables
                            context={'employees'}
                            rowData={employeeData}
                            columnData={_.cloneDeep(wellbeingColumns)}
                        />
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile ag-group-header">
                <p>Other than Permanent employees</p>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <WellbeingTables
                            context={'otherEmployees'}
                            rowData={exceptEmployeeData}
                            columnData={_.cloneDeep(wellbeingColumns)}
                        />
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile ag-group-header">
                <h5>b. Details of measures for the well-being of workers</h5>
                <p>Permanent Wokers</p>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <WellbeingTables
                            context={'workers'}
                            rowData={workersData}
                            columnData={_.cloneDeep(wellbeingColumns)}
                        />
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile ag-group-header">
                <p>Other than Permanent workers</p>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <WellbeingTables
                            context={'otherWorkers'}
                            rowData={exceptWorkerData}
                            columnData={_.cloneDeep(wellbeingColumns)}
                        />
                    </div>
                </div>
            </div>
            <h5 className="mtop-1">c. Spending on measures towards well-being of employees and workers
                (including permanent and other than permanent) in the following format:</h5>
            <h5>Cost incurred on wellbeing measures as a % of total revenue of the company </h5>
            <Row >
                <Col lg='6'>
                    <FormGroup>
                        <label>In current year({financialYear})</label>
                        <input type="number" className="form-control"
                            value={currentYrStatus}
                            onChange={(e) => setCurrentYearStatus(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup>
                        <label>In previous year({previousYear})</label>
                        <input type="number" className="form-control" placeholder=""
                            value={previousYrStatus} onChange={(e) => setPreviousYearStatus(e.target.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>2. Details of retirement benefits, for Current Financial Year and Previous Financial Year</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={retirementDetails}
                                columnData={retirementBenefitsColumn}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    3. Are the premises / offices of the entity accessible to differently
                    abled employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016?
                    If not, whether any steps are being taken by the entity in this regard
                </h5>
                <textarea className="form-control ml-1" value={diffAbledAct} rows={3}
                    onChange={(e) => setDiffAbledAct(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    4. Does the entity have an equal opportunity policy as per the Rights of
                    Persons with Disabilities Act, 2016? If so, provide a web-link to the policy
                </h5>
                <textarea className="form-control ml-1" value={equalityAct} rows={3}
                    onChange={(e) => setEqualityAct(e.target.value)} />
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>5. Return to work and Retention rates of permanent employees and workers that took parental leave</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={returnedData}
                                columnData={returnColumns}
                                gridOptions={gridOptions}
                                onCellValueChanged={parentalRetensionCellChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mtop-1">
                <h5 className="mtop-1">6. Is there a mechanism available to receive and redress grievances for the following
                    categories of employees and workers? If yes, give details of the mechanism in brief</h5>
                <div className="d-flex">
                    <div className="col-6">
                        <label htmlFor="">Permanent Workers</label>
                        <select className="form-select" value={isPermentWorkersMechanism} onChange={(e) => setIsPermentWorkers(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isPermentWorkersMechanism === 'Yes' && <input type="text" className="form-control ml-1 mtop-1-hf" value={wokerMechanism}
                                onChange={(e) => setWorkerMechanism(e.target.value)} />
                        }
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col-6">
                        <label htmlFor="">Other than Permanent Employees</label>
                        <select className="form-select" value={isOtherWorkersMechanism}
                            onChange={(e) => setIsOtherWorkers(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isOtherWorkersMechanism === 'Yes' && <input type="text" className="form-control ml-1 mtop-1-hf" value={otherWokerMechanism}
                                onChange={(e) => setOtherWorkerMechanism(e.target.value)} />
                        }
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col-6">
                        <label htmlFor="">Permanent Employee</label>
                        <select className="form-select" value={isPermentEmployeeMechanism}
                            onChange={(e) => setIsPermentEmployee(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isPermentEmployeeMechanism === 'Yes' && <input type="text" className="form-control ml-1 mtop-1-hf"
                                value={employeeMechanism} onChange={(e) => setEmployeeMechanism(e.target.value)} />
                        }
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col-6">
                        <label htmlFor="">Other than Permanent Workers</label>
                        <select className="form-select" value={isOtherEmployeeMechanism}
                            onChange={(e) => setIsOtherEmployee(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isOtherEmployeeMechanism === 'Yes' && <input type="text" className="form-control ml-1 mtop-1-hf" value={otherEmployeeMechanism}
                                onChange={(e) => setOtheEmployeeMechanism(e.target.value)} />
                        }
                    </div>
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 company-profile ag-group-header">
                    <h5>7. Membership of employees and worker in association(s) or Unions recognised by the listed entity</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={membershipData}
                                columnData={membershipColumn}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 company-profile ag-group-header">
                    <h5>8. Details of training given to employees and workers</h5>
                    <p>Employees</p>
                    <div className="ag-3layer-header">
                        <div className="ag-group-header">
                            <div className="ngrbc-review">
                                <div style={{ width: '100%' }} className="ag-datasheet">
                                    <TrainingComponent
                                        context={'employees'}
                                        rowData={employeeSkillUpgradation}
                                        columnData={_.cloneDeep(trainingColumn)}
                                        setRowData={setEmployeeSkillUpgradation}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mtop-1 company-profile ag-group-header">
                <p>Workers</p>
                <div className="ag-3layer-header">
                    <div className="ag-group-header">
                        <div className="ngrbc-review">
                            <div style={{ width: '100%' }} className="ag-datasheet">
                                <TrainingComponent
                                    context={'workers'}
                                    rowData={WorkersSkillUpgradation}
                                    columnData={_.cloneDeep(trainingColumn)}
                                    setRowData={setWorkersSkillUpgradation}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 company-profile ag-group-header">
                    <h5>9. Details of performance and career development reviews of employees and worker</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <CareerDevelopmentComponent
                                context={'employees'}
                                rowData={employeeCareerData}
                                columnData={_.cloneDeep(careerDevelopmentColumns?.map((ele, index) => ({ ...ele, headerName: index === 0 ? 'Employee' : ele?.headerName })))}

                            />
                        </div>
                    </div>
                    <div className="ngrbc-review mtop-1">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <CareerDevelopmentComponent
                                context={'workers'}
                                rowData={workerCareerData}
                                columnData={_.cloneDeep(careerDevelopmentColumns?.map((ele, index) => ({ ...ele, headerName: index === 0 ? 'Workers' : ele?.headerName })))}

                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mtop-1">
                <h5>10. Health and safety management system</h5>
                <label >a. Whether an occupational health and safety management system has been implemented by the entity? (Yes/ No). If yes, the coverage such system?</label>
                <div className="d-flex">
                    <div className="col-6">
                        <select className="form-select"
                            value={isHSManagement}
                            onChange={(e) => setIsHSManagement(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isHSManagement === 'Yes' && <input type="text" className="form-control ml-1" value={coverageSystem}
                                onChange={(e) => setCoverageSystem(e.target.value)} />
                        }
                    </div>
                </div>
                <div className="d-flex mtop-1">
                    <label className="col-6">
                        b. What are the processes used to identify
                        work-related hazards and assess risks on a routine and non-routine basis by the entity?
                    </label>
                    <div className="col-6">
                        <textarea className="form-control ml-1" value={hazardProcess} rows={3}
                            onChange={(e) => setHazardProcess(e.target.value)} />
                    </div>
                </div>
                <div className="d-flex mtop-1">
                    <label>c. Whether you have processes for workers to report the work-related
                        hazards and to remove themselves from such risks. (Yes/No) </label>
                    <div className="col-6">
                        <select className="form-select ml-1"
                            value={isWorkProcess}
                            onChange={(e) => setIsWorkProcess(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div className="d-flex mtop-1">
                    <label>d. Do the employees/ worker of the entity have
                        access to non-occupational medical and healthcare services? (Yes/ No) </label>
                    <div className="col-6">
                        <select className="form-select ml-1"
                            value={isMedicalServices}
                            onChange={(e) => setMedicalServices(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>11. Details of safety related incidents, in the following format</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={safetyIncidentData}
                        columnData={safetyIncidentColumns}
                    />
                </div>
            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    12. Describe the measures taken by the entity to ensure a safe and healthy workplace.
                </h5>
                <div className="col-6">
                    <textarea className="form-control ml-1" value={safetyMeasures} rows={3}
                        onChange={(e) => setSafetyMeasures(e.target.value)} />
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>13. Number of Complaints on the following made by employees and workers</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={employeeComplaintsData}
                                columnData={complaintsColumn}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <h5 className="mtop-1">14. Assessments for the year</h5>
            <label>% of your plants and offices that were assessed (by entity or statutory authorities or third parties)  </label>
            <Row >
                <Col lg='6'>
                    <FormGroup>
                        <label>Health and safety practices</label>
                        <input type="number" className="form-control" value={healthAssessment}
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setHealthAssessment(0) : setHealthAssessment(e.target.value)}
                            onChange={(e) => setHealthAssessment(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup>
                        <label>Working Conditions  </label>
                        <input type="number" className="form-control" placeholder=""
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setWorkingAssessment(0) : setWorkingAssessment(e.target.value)}
                            value={workingAssessment} onChange={(e) => setWorkingAssessment(e.target.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    15. Provide details of any corrective action taken or underway to address safety-related incidents
                    (if any) and on significant risks / concerns arising from assessments of health & safety practices and working conditions
                </h5>
                <div className="col-6">
                    <textarea className="form-control ml-1" value={assessmentDetails} rows={3}
                        onChange={(e) => setAssessmentDetails(e.target.value)} />
                </div>
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <h5 className="mtop-1">1. Does the entity extend any life insurance or any compensatory package in the event of death of</h5>
            <Row >
                <Col lg='6'>
                    <FormGroup>
                        <label>Employees (Yes/No)</label>
                        <select className="form-select" value={isApplyInsuranceEmployee}
                            onChange={(e) => setApplyInsuranceEmployee(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup>
                        <label>Workers (Yes/No)</label>
                        <select className="form-select" value={isApplyInsuranceWorker}
                            onChange={(e) => setApplyInsuranceWorker(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </FormGroup>
                </Col>
            </Row>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    2. Provide the measures undertaken by the entity to ensure
                    that statutory dues have been deducted and deposited by the value chain partners
                </h5>
                <div className="col-6">
                    <textarea className="form-control ml-1" value={statutoryMeasurements} rows={3}
                        onChange={(e) => setStatutoryMeasurements(e.target.value)} />
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>3. Number of Employees/Workers with High-Consequence Work-Related Injuries/Ill-Health/Fatalities (as reported in Q11)
                        Rehabilitated and Placed in Suitable Employment, or Whose Family Members Have Been Employed</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={rehabilitationData}
                                columnData={selfEmployedColumns}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    4 Does the entity provide transition assistance programs
                    to facilitate continued employability and the management of career endings resulting from retirement or termination of employment?
                </h5>
                <div className="col-6">
                    <select className="form-select ml-1" value={retirementPlan}
                        onChange={(e) => setRetirementPlan(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <h5 className="mtop-1">5. Details on assessment of value chain partners</h5>
            <label>% of value chain partners (by value of business done with such partners) that were assessed  </label>
            <Row >
                <Col lg='6'>
                    <FormGroup>
                        <label>Health and safety practices</label>
                        <input type="number" className="form-control" value={healthAssessmentPercentage}
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setHealthAssessmentPercentage(0) : setHealthAssessmentPercentage(e.target.value)}
                            onChange={(e) => setHealthAssessmentPercentage(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup>
                        <label>Working Conditions  </label>
                        <input type="number" className="form-control" placeholder=""
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setWorkingAssessmentPercentage(0) : setWorkingAssessmentPercentage(e.target.value)}
                            value={workingAssessmentPercentage} onChange={(e) => setWorkingAssessmentPercentage(e.target.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    6. Provide details of any corrective actions taken or underway to address significant risks / concerns arising from
                    assessments of health and safety practices and working conditions of value chain partners.
                </h5>
                <div className="col-6">
                    <textarea className="form-control ml-1" value={riskDetails} rows={3}
                        onChange={(e) => setRiskDetails(e.target.value)} />
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(2)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>

        </Fragment>
    )
}

export default EmployeeWellbeing;