import React, { Fragment, useEffect, useRef, useState } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { H3 } from "../../../AbstractElements";
import { Button } from "reactstrap";
import { Nav, Tab } from "react-bootstrap";
import { Plus, Trash2, XCircle } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { thousandSeparator } from "../../api/helper";
import { alertFunction, deleteCacheData, getCacheData, notifyFunction, storeData, uuidGenerator } from './ghgHelper';
import { useSelector } from "react-redux";
import moment from "moment";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { PiFileArrowDownDuotone } from "react-icons/pi";

let error = false, errorTable2 = false;
const IndirectWaste = ({ setSteps, preData, timeStamp, directWasteData, setDirectWasteData, setIndirectWaste, indirectWasteData, requestId, setRequestId }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })));
    const [directWasteSourceData, setDirectWaste] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })))
    const [noOfRow, setNoOfRow] = useState(1);
    const [noOfDirectRow, setNoOfDirectRow] = useState(1);
    const wasteRef = useRef(null);
    const directRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState([]);
    const [rowIndexOfDirect, setRowIndexOfDirect] = useState([]);
    const [wasteMaterial, setWasteMaterial] = useState([]);
    const [wasteMaterialData, setWasteMaterialData] = useState([]);
    const [disposalTypes, setDisposalTypes] = useState();
    const [directTypes, setDirectTypes] = useState();
    const [divertedTypes, setDivertTypes] = useState();
    const [directWasteMaterial, setDirectWasteMaterial] = useState([]);
    const [directWasteMaterialData, setDirectWasteMaterialData] = useState([]);
    const [gridLoading, setGridLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState();
    const [activeKey, setActiveKey] = useState('2');
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }
    const defaultTabRef = useRef(null);

    const getWasteMaterialData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/WasteEmissions/${preData?.countryCode}`)
            .then((res) => {
                const data = res?.json?.map(ele => ele.warmMaterial)
                setWasteMaterial(data)
                setWasteMaterialData(res.json);
            }, (err) => console.log(err))
    }

    const getDisposalTypes = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/disposalTypes`)
            .then((res) => {
                setDisposalTypes(res.json)
            }, (err) => { console.log(err) })
    }

    const getDivertTypes = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/disposalMethods/${2}`)
            .then((res) => {
                setDivertTypes(res.json)
            }, (err) => { console.log(err) })
    }

    const getDirectTypes = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/disposalMethods/${1}`)
            .then((res) => {
                setDirectTypes(res.json)
            }, (err) => { console.log(err) })
    }

    const getDirectWasteMaterial = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/DirectWaste/${preData?.countryCode}`)
            .then((res) => {
                const data = res?.json?.map(ele => ele.wasteMaterial)
                setDirectWasteMaterial(data)
                setDirectWasteMaterialData(res.json);
            }, (err) => console.log(err))
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        if (indirectWasteData?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(indirectWasteData)
        } else if (indirectWasteData?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if (indirectWasteData?.length > 0 && savedData?.length > 0) {
            console.log(timeStamp, cTimestamp);
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, indirectWasteData, populateTable)
            } else { //saved is latest
                notifyFunction(indirectWasteData, populateTable)
            }
        }
    }

    const populateDirectWasteTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setDirectWaste(tempData)
    }

    const loadDirectWasteData = (savedData, cTimestamp) => {
        if (directWasteData?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateDirectWasteTable(directWasteData)
        } else if (directWasteData?.length === 0 && savedData?.length > 0) {
            populateDirectWasteTable(savedData)
        } else if (directWasteData?.length > 0 && savedData?.length > 0) {
            console.log(timeStamp, cTimestamp);
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, directWasteData, populateDirectWasteTable)
            } else { //saved is latest
                notifyFunction(directWasteData, populateDirectWasteTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        loadData(cacheData?.indirectWasteData, cacheData?.modifiedTs);
        loadDirectWasteData(cacheData?.directWasteData, cacheData?.modifiedTs);
    }



    useEffect(() => {
        console.log(activeKey, typeof activeKey)
        if (defaultTabRef.current) {
            defaultTabRef.current.focus();
        }
    }, [activeKey])

    useEffect(() => {
        getWasteMaterialData()
        getDisposalTypes()
        getDivertTypes()
        getDirectTypes()
        getDirectWasteMaterial()
        getCache()

    }, [])

    const handleClear = async (params, type) => {
        let tempData, updatedRowData;
        if (type === 1) {
            updatedRowData = data.map((row, index) => {
                return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                    : ((row.id === params.data.id) && row.isSubmit === false) ?
                        { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                        : row
            });
            tempData = {
                ...cacheData,
                indirectWasteData: updatedRowData?.filter(ele => ele?.sourceId),
                directWasteData: directWasteSourceData?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if (type === 2) {
            updatedRowData = directWasteSourceData.map((row, index) => {
                return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                    : ((row.id === params.data.id) && row.isSubmit === false) ?
                        { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                        : row
            });
            tempData = {
                ...cacheData,
                indirectWasteData: data?.filter(ele => ele?.sourceId),
                directWasteData: updatedRowData?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        }
        await deleteCacheData(keyObject, tempData)
        type === 1 ? setData(updatedRowData) : setDirectWaste(updatedRowData);
    };

    const handleUpload = async (e, params, nodeIndex, type) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            if (type === 1)
                data[nodeIndex].id = id;
            else
                directWasteSourceData[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('file', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=IndirectWaste&uploadPeriod=${preData?.uploadPeriod}
            &categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}
            &requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                let tempData = type === 1 ? [...data] : [...directWasteSourceData];
                tempData[nodeIndex].documentId = res?.json?.id;
                tempData[nodeIndex].uploadedFileName = res?.json?.uploadedFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                type === 1 ? setData(tempData) : setDirectWaste(tempData);
                type === 1 ? createCache(data?.filter(ele => ele?.sourceId)) : createCache(directWasteSourceData?.filter(ele => ele?.sourceId))
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const downloadEvidence = async (paramsData) => {
        await fetchWrapper(`${API_URL}/document/downloadEvidence/${paramsData?.documentId}`)
            .then((err) => {
                console.log(err.json)
                if (err.status === 200) {
                    const link = document.createElement('a');
                    link.href = err?.json?.presignedUrl;
                    link.download = paramsData.uploadedFileName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
            .catch((err) => {
                console.log(err, 'asdasd')
            })
    }

    const directColumnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('wasteMaterial')
                    || params.data.hasOwnProperty('disposalType')
                    || params.data.hasOwnProperty('disposalMethod')
                    || params.data.hasOwnProperty('wasteClassification')
                    || params.data.hasOwnProperty('disposalLocation')
                    || params.data.hasOwnProperty('wasteType')
                    || params.data.hasOwnProperty('weight')
                    || params.data.hasOwnProperty('units')
                ) {
                    params.data.sourceId = `DW-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            width: 80,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Waste Material',
            field: 'wasteMaterial',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: directWasteMaterial,
            },
            valueGetter: (params) => {
                if (params.data.wasteMaterial) {
                    const wasteData = directWasteMaterialData?.find(ele => ele?.wasteMaterial === params.data?.wasteMaterial)
                    params.data.resourceType = wasteData?.resourceType;
                    return params.data.wasteMaterial;
                }
            },
            filter: false,
            editable: true,
            autoHeight: true,
            wrapText: true,
            flex: 1.1,
        },
        {
            headerName: 'Disposal Type',
            field: 'disposalType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: disposalTypes?.map(ele => ele?.disposalType),
            },
            valueGetter: (params) => {
                return params.data.disposalType;
            },
            filter: false,
            editable: true,
            sortable: false,
            autoHeight: true,
            wrapText: true,
            flex: 1.2
        },
        {
            headerName: 'Disposal Method',
            field: 'disposalMethod',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.disposalType === 'Directed to disposal') {
                    return {
                        values: directTypes
                    }
                } else {
                    return {
                        values: divertedTypes
                    }
                }
            },
            editable: true,
            filter: false,
            flex: 1.5,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Hazardous / Non-hazardous',
            field: 'wasteClassification',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = directWasteMaterialData?.find(ele => ele?.wasteMaterial === params.data?.wasteMaterial);
                    let classificationData = wasteData?.classificationType?.split(':');
                    if (classificationData?.length > 1) {
                        return { values: classificationData }
                    }
                }
            },
            filter: false,
            editable: true,
            flex: 1.15,
        },
        {
            headerName: 'Onsite / Offsite',
            field: 'disposalLocation',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = directWasteMaterialData?.find(ele => ele?.wasteMaterial === params.data?.wasteMaterial);
                    let locationData = wasteData?.location?.split(':');
                    if (locationData?.length > 1) {
                        return { values: locationData }
                    }
                }
            },
            filter: false,
            editable: true,
            flex: 1,
        },
        {
            headerName: 'Organic / Inorganic',
            field: 'wasteType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = directWasteMaterialData?.find(ele => ele?.wasteMaterial === params.data?.wasteMaterial);
                    let wasteTypeData = wasteData?.wasteType?.split(':');
                    if (wasteTypeData?.length > 1) {
                        return { values: wasteTypeData }
                    }
                }
            },
            filter: false,
            editable: true,
            flex: 1,
        },
        {
            headerName: 'Weight',
            field: 'weight',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.weight ? thousandSeparator(params?.data?.weight?.toFixed(4)) : ''}</div>;
            },
            flex: 1,
            editable: true,
        },
        {
            headerName: 'Unit',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = directWasteMaterialData?.find((item) => item?.wasteMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = directWasteMaterialData?.find((item) => item?.wasteMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData,
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = directWasteMaterialData?.find((item) => item?.wasteMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return params.data.units;
                    }
                }
                if (params.data.units) {
                    return params.data.units;
                }
            },
            sortable: false,
            autoHeight: true,
            wrapText: true,
            flex: 1,
        },
        {
            headerName: 'Evidence',
            autoHeight: true,
            wrapText: true,
            tooltipValueGetter: (params) => {
                if (params.data.uploadedFileName) {
                    return params.data.uploadedFileName;
                }
            },
            cellRenderer: (params) => {
                if (!params.data.uploadedFileName) {
                    return (
                        <div>
                            <label className="upload-file-icon">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex, 2)}
                                />
                                <AiOutlineCloudUpload />
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div onClick={() => downloadEvidence(params.data)}>
                            <PiFileArrowDownDuotone className="file-download" />
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center',
            width: 80,
        },
        {
            headerName: 'Clear',
            width: 60,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 2)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ];

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('wasteMaterial')
                    || params.data.hasOwnProperty('disposalMethod')
                    || params.data.hasOwnProperty('wasteClassification')
                    || params.data.hasOwnProperty('disposalLocation')
                    || params.data.hasOwnProperty('wasteType')
                    || params.data.hasOwnProperty('weight')
                    || params.data.hasOwnProperty('units')
                ) {
                    params.data.sourceId = `IW-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            width: 80,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Waste Material',
            field: 'wasteMaterial',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: wasteMaterial,
            },
            valueGetter: (params) => {
                if (params.data.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial)
                    params.data.resourceType = wasteData?.resourceType;
                    return params.data.wasteMaterial;
                }
            },
            filter: false,
            editable: true,
            flex: 1.5,
        },
        {
            headerName: 'Disposal Method',
            field: 'disposalMethod',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let disposalMethod = wasteData?.disposalMethod?.split(':');
                    if (disposalMethod?.length > 1) {
                        return { values: disposalMethod }
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let disposalMethod = wasteData?.disposalMethod?.split(':');
                    if (disposalMethod?.length === 1) {
                        params.data.disposalMethod = disposalMethod[0];
                        return params.data.disposalMethod;
                    }
                }
                if (params.data?.disposalMethod) {
                    return params.data?.disposalMethod
                }
            },
            editable: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let disposalMethod = wasteData?.disposalMethod?.split(':');
                    if (disposalMethod?.length > 1) {
                        return true
                    }
                } return false;
            },
            filter: false,
            flex: 0.8,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Hazardous / Non-hazardous',
            field: 'wasteClassification',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let classificationData = wasteData?.wasteClassification?.split(':');
                    if (classificationData?.length > 1) {
                        return { values: classificationData }
                    }
                }
            },
            filter: false,
            editable: true,
            flex: 0.9,
        },
        {
            headerName: 'Onsite / Offsite',
            field: 'disposalLocation',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let locationData = wasteData?.location?.split(':');
                    if (locationData?.length > 1) {
                        return { values: locationData }
                    }
                }
            },
            filter: false,
            editable: true,
            flex: 0.8,
        },
        {
            headerName: 'Organic / Inorganic',
            field: 'wasteType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    const wasteData = wasteMaterialData?.find(ele => ele?.warmMaterial === params.data?.wasteMaterial);
                    let wasteTypeData = wasteData?.wasteType?.split(':');
                    if (wasteTypeData?.length > 1) {
                        return { values: wasteTypeData }
                    }
                }
            },
            filter: false,
            editable: true,
            flex: 1,
        },
        {
            headerName: 'Weight',
            field: 'weight',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.weight ? thousandSeparator(params?.data?.weight?.toFixed(4)) : ''}</div>;
            },
            flex: 1,
            editable: true,
        },
        {
            headerName: 'Unit',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = wasteMaterialData?.find((item) => item?.warmMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = wasteMaterialData?.find((item) => item?.warmMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData,
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.wasteMaterial) {
                    let wasteData = wasteMaterialData?.find((item) => item?.warmMaterial === params.data?.wasteMaterial);
                    let unitData = wasteData?.unitsDescription?.split(':');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return params.data.units;
                    }
                }
                if (params.data.units) {
                    return params.data.units;
                }
            },
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Evidence',
            autoHeight: true,
            wrapText: true,
            tooltipValueGetter: (params) => {
                if (params.data.uploadedFileName) {
                    return params.data.uploadedFileName;
                }
            },
            cellRenderer: (params) => {
                if (!params.data.uploadedFileName) {
                    return (
                        <div>
                            <label className="upload-file-icon">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex, 1)}
                                />
                                <AiOutlineCloudUpload />
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div onClick={() => downloadEvidence(params.data)}>
                            <PiFileArrowDownDuotone className="file-download" />
                        </div>
                    )
                }
            },
            suppressMovable: true,
            width: 80,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 1)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ];

    const createCache = async (data) => {
        const tempData = {
            ...cacheData,
            requestId: requestId,
            directWasteData: directWasteSourceData?.filter(ele => ele?.sourceId),
            indirectWasteData: data?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = async (event) => {
        if (event.colDef.field === 'disposalType') {
            delete event.data.disposalMethod;
        }
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache(data)
    }

    const addingRows = (val, type) => {
        type === 1 ? setNoOfRow(val) : setNoOfDirectRow(val)
    }

    const addRows = (type) => {
        const typeOfRow = type === 1 ? noOfRow : noOfDirectRow;
        const rowData = [];
        for (let i = 1; i <= typeOfRow; i++) {
            const length = type === 1 ? data?.length : directWasteSourceData?.length;
            rowData.push({
                id: length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            })
        }
        type === 1 ? setData([...data, ...rowData]) : setDirectWaste([...directWasteSourceData, ...rowData]);
    }

    const getGridData = (type) => {
        if (type === 1) {
            if (wasteRef.current) {
                const tableState = data
                    .filter((rowNode) => rowNode.sourceId)
                    .map((rowNode, index) => {
                        if (rowNode.sourceDescription && rowNode.wasteMaterial
                            && rowNode.disposalMethod && rowNode.wasteClassification
                            && rowNode.disposalLocation && rowNode.wasteType
                            && rowNode.weight && rowNode.units) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                sourceDescription: rowNode.sourceDescription,
                                resourceType: rowNode.resourceType,
                                wasteMaterial: rowNode.wasteMaterial,
                                disposalMethod: rowNode.disposalMethod,
                                wasteClassification: rowNode.wasteClassification,
                                disposalLocation: rowNode.disposalLocation,
                                wasteType: rowNode.wasteType,
                                weight: rowNode.weight,
                                documentId: rowNode.documentId ? rowNode.documentId : null,
                                uploadedFileName: rowNode.uploadedFileName ? rowNode.uploadedFileName : null,
                                units: rowNode.units,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode?.sourceId.split('-')[1]);
                            return {}
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    error = true;
                } else {
                    error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        } else if (type === 2) {
            if (directRef.current) {
                const tableState = directWasteSourceData
                    .filter((rowNode) => rowNode.sourceId)
                    .map((rowNode, index) => {
                        if (rowNode.sourceDescription && rowNode.wasteMaterial
                            && rowNode.disposalMethod && rowNode.wasteClassification && rowNode.disposalType
                            && rowNode.disposalLocation && rowNode.wasteType
                            && rowNode.weight && rowNode.units) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                sourceDescription: rowNode.sourceDescription,
                                disposalType: rowNode.disposalType,
                                resourceType: rowNode.resourceType,
                                wasteMaterial: rowNode.wasteMaterial,
                                disposalMethod: rowNode.disposalMethod,
                                wasteClassification: rowNode.wasteClassification,
                                disposalLocation: rowNode.disposalLocation,
                                wasteType: rowNode.wasteType,
                                weight: rowNode.weight,
                                units: rowNode.units,
                                documentId: rowNode.documentId ? rowNode.documentId : null,
                                uploadedFileName: rowNode.uploadedFileName ? rowNode.uploadedFileName : null,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndexOfDirect.push(rowNode?.sourceId.split('-')[1]);
                            return {}
                        }
                    });
                console.log(tableState)
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    errorTable2 = true;
                } else {
                    errorTable2 = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        }

    }

    const onSubmit = async () => {
        const gridData = getGridData(1);
        const directGridData = getGridData(2);
        if (error === true || errorTable2 === true) {
            if (error) {
                toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')} in Indirect waste`);
                setRowIndex([])
                error = false
            }
            if (errorTable2 === true) {
                toast.error(`Please fill missing values for row ${[...new Set(rowIndexOfDirect)].join(',')} in Direct waste`)
                setRowIndexOfDirect([])
                errorTable2 = false;
            }
            return
        } else {
            setIndirectWaste(gridData);
            setDirectWasteData(directGridData)
            setSteps(0);
        }
    }

    return (
        <Fragment className="">
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Organization-Wide Waste Material by Weight
                    and Disposal Method (CO<sub>2</sub>, CH<sub>4</sub> and N<sub>2</sub>O)</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            <div className="data-capture">
                <Tab.Container activeKey={activeKey} defaultActiveKey={'2'} onSelect={(key) => setActiveKey(key)} >
                    <Nav variant="underlines">
                        <Nav.Item className="mr-5px">
                            <Nav.Link eventKey={'2'} className={activeKey == 2 ? 'active' : ''} ref={defaultTabRef}>Direct</Nav.Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Nav.Link eventKey={'1'} className={activeKey == 1 ? 'active' : ''} >Indirect</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="mtop-1">
                        <Tab.Pane eventKey={'1'}>
                            <div className="mtop-1">
                                <div className="ag-datasheet">
                                    <AgGridDatasheet
                                        tableData={data?.filter(ele => ele?.isDelete === false)}
                                        columnData={columnData}
                                        agRef={wasteRef}
                                        onCellValueChanged={onCellValueChanged}
                                    />
                                    <div className="ag-custom-footer">
                                        <div className="d-flex ">
                                            <button className="add-row-button" onClick={addRows}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                            <input
                                                type="number"
                                                className="ml-1 input-number"
                                                value={noOfRow}
                                                onChange={(e) => addingRows(e.target.value, 1)}
                                            />
                                            <h5 className="ml-1">rows</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'2'}>
                            <div className="mtop-1">
                                <div className="ag-datasheet">
                                    <AgGridDatasheet
                                        tableData={directWasteSourceData?.filter(ele => ele?.isDelete === false)}
                                        columnData={directColumnData}
                                        agRef={directRef}
                                        onCellValueChanged={onCellValueChanged}
                                    />
                                    <div className="ag-custom-footer">
                                        <div className="d-flex ">
                                            <button className="add-row-button" onClick={addRows}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                            <input
                                                type="number"
                                                className="ml-1 input-number"
                                                value={noOfDirectRow}
                                                onChange={(e) => addingRows(e.target.value, 2)}
                                            />
                                            <h5 className="ml-1">rows</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>



        </Fragment>
    )
}

export default IndirectWaste;