import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button } from "reactstrap";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import { Trash2 } from "react-feather";

const ProductService = ({ financialYear }) => {
    const [activityData, setActivityData] = useState([...Array(3)].map((_, index) => ({ serialNo: index + 1 })));
    const [productData, setProductData] = useState([...Array(3)].map((_, index) => ({ serialNo: index + 1 })));

    const getActivityData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/businessActivitiesData/${financialYear}`)
            .then((res) => {
                setActivityData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getProductData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/productsServicesData/${financialYear}`)
            .then((res) => {
                setProductData(res.json)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (financialYear) {
            getActivityData()
            getProductData()
        }
    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
        if (type == 1) {
            updatedRowData = activityData?.map((row, index) => {
                return ((index === params.node.rowIndex) ? { serialNo: index + 1 } : {...row})
            })
            setActivityData(updatedRowData);
        } else if(type == 2) {
            updatedRowData = productData?.map((row, index) => {
                return ((index === params.node.rowIndex) ? { serialNo: index + 1 } : {...row})
            })
            setProductData(updatedRowData)
        }
    }

    const activityColumnData = [
        {
            headerName: 'S.No',
            field: 'serialNo',
            editable: false,
            valueGetter: (params) => {
                return params.node.rowIndex + 1
            },
            filter: false,
            flex: 0.5,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Description of Main Activity',
            field: 'descriptionMainActivity',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Description of Business Activity',
            field: 'descriptionBusinessActivity',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '% of Turnover of the entity',
            field: 'percentageTurnOver',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentageTurnOver ?
                    thousandSeparator(params?.data?.percentageTurnOver.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('descriptionMainActivity') ||
                    params.data.hasOwnProperty('descriptionBusinessActivity') ||
                    params.data.hasOwnProperty('percentageTurnOver')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)}/>
                        </div>
                    )
            }
        }
    ];

    const productColumnData = [
        {
            headerName: 'S.No',
            field: 'serialNo',
            editable: false,
            filter: false,
            valueGetter: (params) => {
                return params.node.rowIndex + 1
            },
            flex: 0.5,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Product/Service',
            field: 'productService',
            editable: true,
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Industry Classification Code',
            field: 'nicCode',
            editable: true,
            cellEditor: 'agTextCellEditor',
            cellEditorParams: {
                maxLength: 6
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: '% of total Turnover contributed',
            field: 'percentageTurnOver',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentageTurnOver ?
                    thousandSeparator(params?.data?.percentageTurnOver.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('productService') ||
                    params.data.hasOwnProperty('nicCode') ||
                    params.data.hasOwnProperty('percentageTurnOver')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 2)}/>
                        </div>
                    )
            }
        }
    ];

    const onSubmit = async () => {
        const data = {
            financialYear: financialYear,
            businessActivitiesData: activityData?.filter(item => Object.keys(item)?.length > 2),
            productsServicesData: productData?.filter(item => Object.keys(item)?.length > 2)
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)

            }, (err) => {
                console.log(err)
                if (err?.status === 200) {
                    toast.success('Data saved successfully!')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }

            })

    }


    return (
        <Fragment>
            <h3 className="f-15">Products/Services</h3>
            <div className="mtop-1 company-profile">
                <h5>Details of business activities (accounting for 90% of the turnover)</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={activityData}
                        columnData={activityColumnData}
                    />
                    <CustomFooter handleAdd={() => setActivityData([...activityData, { serialNo: activityData?.length + 1 }])} />
                </div>
            </div>

            <div className="mtop-1">
                <h5>Products/Services sold by the entity (accounting for 90% of the entity’s Turnover)</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={productData}
                        columnData={productColumnData}
                    />
                    <CustomFooter handleAdd={() => setProductData([...productData, { serialNo: productData?.length + 1 }])} />

                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="primary" disabled={!financialYear ? true : false} onClick={onSubmit}>Submit</Button>
            </div>
        </Fragment>
    )
}

export default ProductService;